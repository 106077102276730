import { Component, OnInit } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BuysellService } from '../services/buysell.service';


@Component({
  selector: 'app-buysell.component',
  templateUrl: './buysell.component.html',
  styleUrls: ['./buysell.component.scss']
})
export class BuySellComponent implements OnInit {
  usersBuySell: any;
  
  constructor(
    public buysellService: BuysellService,
  ) { }

  ngOnInit() {
    this.getUsersBuySell();
  }

  getUsersBuySell() {

    this.buysellService.getUsersBuySell().subscribe((buysell: any) => {
      this.usersBuySell = buysell;
    }, err => {
      console.log(err);
    });
  }
}
