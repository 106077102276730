import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FaucetConfigService {

  constructor(
    private db: AngularFirestore,
    public afs: AngularFirestore,
    private http: HttpClient
  ) { }
  getFaucetConfig() {
    return this.db.collection('Config').doc('Faucet').valueChanges();
  }
}
