import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask  } from '@angular/fire/storage';

export interface DialogData {
  coinName: string;
  iconPath: string;
}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: './dialog-overview-example-dialog.html',
  styleUrls: ['./dialog-overview-example-dialog.css']
})
export class DialogOverviewExampleDialog {
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  iconPath: string;
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private afStorage: AngularFireStorage
  ) {
      this.iconPath = data.iconPath;
      
	  
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  upload(event) {
    // this.afStorage.upload('/assets/', event.target.files[0]);
    this.iconPath = 'PROCESSING';
    let fileName = this.data.coinName;
    //fileName = fileName.replace('.', '_');
    fileName = fileName.toLowerCase();

    let fileWithExtn = event.target.files[0].name.toString().split('.');
    let fileExtn = fileWithExtn[fileWithExtn.length - 1].toLowerCase();
    
    this.ref = this.afStorage.ref('/assets/'+fileName+'.'+fileExtn);
    this.task = this.ref.put(event.target.files[0]);
    setTimeout(() => {
        this.afStorage.ref('/assets/'+fileName+'.'+fileExtn).getDownloadURL().subscribe(filePath => {
            
            setTimeout(() => {
              this.iconPath = filePath;
            }, 3000);
            
        }, err => {
          console.log('Get uploaded icon path error', err);
        });
    }, 2000);
  }
  
}

