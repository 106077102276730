import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { UsersService } from './users.service';
import { map, switchMap, filter, flatMap } from 'rxjs/operators'
@Injectable({
  providedIn: 'root'
})
export class SavingsService {

  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
    public userService: UsersService,
  ) { }
  
  getUsersSavings() {
    return new Promise<any>((resolve, reject) => {
      const userSavings = this.userService.getUsersSavings().subscribe(users => {
        return users;
      }, error => {
        console.log('error', error);
      });
	  //return userSavings;
	  resolve(userSavings)
	});
  }
  
  getBalance(uid) {
	const usersAllBalance = this.db.doc(`Users/${uid}`).collection(`balance`).snapshotChanges()
    .pipe(
      map(changes => changes.map(a => {
        const data = a.payload.doc.data() as any;
		//console.log('baalnce daya', data);
        data.coin = a.payload.doc.id;
        return data;
      }))
    );
    return usersAllBalance;
  }
  
}
