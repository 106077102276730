import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { UsersService } from '../services/users.service';
import { ConfigService } from '../services/config.service';
import { MarketsService } from '../services/markets.service';
import { GlobalvariableProvider } from '../providers/globalprovider';
import { Router } from '@angular/router';
import firebase from 'firebase/app';

@Component({
  selector: 'app-usermanagement',
  templateUrl: './usermanagement.component.html',
  styleUrls: ['./usermanagement.component.css']
})

export class UsermanagementComponent implements OnInit {
  hide = false;
  modalReference: any;
  allUsers: any;
  user: any;
  pageActual = 1;
  jsonCore: any = JSON;
  searchText;
  savingUser: any;
  supportedAssets: any;
  exchangeRates: any;
  constructor(
    private modalService: NgbModal,
    private userService: UsersService,
    private marketsService: MarketsService,
    public global: GlobalvariableProvider,
    private router: Router,
    public configService: ConfigService,
  ) { }

  ngOnInit() {
    this.getBitsharesMarket();
    if (!this.global.loggedin) {
      this.router.navigate(['/']);
    }
    this.userService.getCurrentUser().then(res => {
      if (res) {
        this.user = res;
        firebase.auth().currentUser.getIdToken().then(authToken => {
          // this.getSupportedAssets(authToken);
        });
      }
    });
  }
  getBitsharesMarket() {
    this.marketsService.getBitSharesMarket().subscribe(markets => {
      this.exchangeRates = markets;
      this.getAllUsers(markets);
      console.log('markets', markets);
    }, err => {
      console.log(err);
    });
  }
  // Creates new report, triggering FCF
  userExport() {
    this.userService.downloadFile(this.allUsers, 'userdata');
  }
  deletePop(deleteopen, user) {
    this.user = user;
    this.modalReference = this.modalService.open(deleteopen, {backdrop: 'static'});
  }
  deleteUser(userId) {
    this.userService.deleteUser(userId).then(success => {
      this.modalReference.close();
    }).catch(error => {
      console.log('error', error);
    });
  }
  closePop(popclose) {
    this.modalReference.close();
  }
  editPop(editopen, user) {
    this.user = user;
    this.modalReference = this.modalService.open(editopen, {backdrop: 'static', size: 'lg'});
  }

  updateUser(user) {
    this.userService.updateUser(user).then(success => {
      this.modalReference.close();
    }).catch(error => {
      console.log('error', error);
    });
  }
  
  addUserSaving(user) {

    const balanceData = {
      'balance' : {
        [user.saveCoin] : user.saveAmount
      }, 
      'status': 'Accepted', 
      'updated': user.adddate,
      'type': user.saveType
    };
    this.userService.addUserBalance(user, balanceData).then(success => {
      console.log('success', success);
      this.modalReference.close();
    }).catch(error => {
      console.log('error', error);
    });
  }

  changeUserStatus(user) {
    if (user.status) {
      user.status = false;
    } else {
      user.status = true;
    }
    this.userService.updateUser(user).then(success => {
    }).catch(error => {
      console.log('error', error);
    });
  }

  getAllUsers(markets) {
    this.userService.getAllUsers(markets).subscribe(users => {
      this.allUsers = users;
      console.log('users', users);
    }, error => {
      console.log('error', error);
    });
  }
  
  addSavingPop(addSavings, user) {
    this.savingUser = user;
    this.savingUser.saveType = '';
    this.savingUser.saveCoin = '';
    this.savingUser.saveAmount = '';
    this.savingUser.adddate = '';
    
    this.modalReference = this.modalService.open(addSavings, {backdrop: 'static', size: 'lg'});
  }
  
  // getSupportedAssets(accessToken) {
  //   this.configService.getSupportedAssets(accessToken).subscribe(supportedAssets => {
  //     this.supportedAssets = supportedAssets['assets_supported'];

  //   }, err => {
  //     console.log('err', err);
  //   });
  // }
}
