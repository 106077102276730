import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

import { Markets } from './../models/markets';

@Injectable({
  providedIn: 'root'
})
export class MarketsService {
  // private marketCollection: AngularFirestoreCollection<Markets>;

  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
  ) {
    // this.marketCollection = this.db.collection('BitShares_Market');
  }
  
  getBitSharesMarket() {
    return this.db.collection('BitShares_Market').valueChanges(); //
  }
}
