import { Component, OnInit } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MarketsService } from '../services/markets.service';
import { Markets } from '../models/markets';

@Component({
  selector: 'app-markets',
  templateUrl: './markets.component.html',
  styleUrls: ['./markets.component.scss']
})
export class MarketsComponent implements OnInit {
  markethistory: any = [];
  markets: Markets[] = [];
  sortedData: Markets[];
  //prevData: any;
  displayedColumns: string[] = ['percentChange', 'price', 'symbol', 'updated'];
  constructor(
    public marketsService: MarketsService,
  ) { }

  ngOnInit() {
    this.getBitsharesMarket();
  }
  
  getBitsharesMarket() {
    this.marketsService.getBitSharesMarket().subscribe(markets => {
      if (markets.length > 0) {
        this.markets.length = 0;
        markets.forEach((pitem, pind) => {
          if (!this.markethistory[pitem['pair']]) {
            this.markethistory[pitem['pair']] = [];
            this.markethistory[pitem['pair']].push(pitem['price']);
          } else {
            const eplaslen = this.markethistory[pitem['pair']].length - 1;
            if (this.markethistory[pitem['pair']][eplaslen] !== pitem['price']) {
              this.markethistory[pitem['pair']].push(pitem['price']);
            }
          }
	  this.markets.push({'pair' : pitem['pair'], 'percentChange' : pitem['percentChange'], 'price' : pitem['price'], 'updated' : pitem['updated'], 'volume' : pitem['volume']});
        });
      }
      this.sortedData = this.markets.slice();
    }, err => {
      console.log(err);
    });
  }
  
  sortData(sort: Sort) {
    const data = this.markets.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'pair': return compare(a.pair, b.pair, isAsc);
        case 'percentChange': return compare(a.percentChange, b.percentChange, isAsc);
        case 'price': return compare(a.price, b.price, isAsc);
        case 'updated': return compare(a.updated, b.updated, isAsc);
        case 'volume': return compare(a.volume, b.volume, isAsc);
        default: return 0;
      }
    });
  }
  setNotification(prevVal, currVal, pairLen) {
    let flashFlag = false;
    let greenFlag = false;
    let redFlag = false;
    if(prevVal !== currVal && pairLen > 1) {
      flashFlag = true;
    }
    if(prevVal < currVal && pairLen > 1) {
      greenFlag = true;
    }
    if(prevVal > currVal && pairLen > 1) {
      redFlag = true;
    }
    if(prevVal == currVal && pairLen > 1) {
      //redFlag = true;
    }
    return {
      'flash' : flashFlag,
      'greenColor' : greenFlag,
      'redColor': redFlag,
    }
  }
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}