import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from '../services/config.service';
import { Router } from '@angular/router';
import { GlobalvariableProvider } from '../providers/globalprovider';

@Component({
  selector: 'app-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrls: ['./withdraw.component.css']
})
export class WithdrawComponent implements OnInit {
  modalReference: any;
  config: any;
  assetManagement: any;
  hide = false;
  constructor(
    private modalService: NgbModal,
    public configService: ConfigService,
    private router: Router,
    public global: GlobalvariableProvider,
  ) { }

  ngOnInit() {
    // this.getEnabledAssetsCoins();
    if (!this.global.loggedin) {
      this.router.navigate(['/']);
    }
  }
  editPop(editopen) {
    if (this.modalReference) {
      this.modalReference.close();
    }
    this.modalReference = this.modalService.open(editopen, {backdrop: 'static'});
  }
  addPop(addopen) {
    if (this.modalReference) {
      this.modalReference.close();
    }
    this.modalReference = this.modalService.open(addopen, {backdrop: 'static'});
  }
  deletePop(deleteopen) {
    if (this.modalReference) {
      this.modalReference.close();
    }
    this.modalReference = this.modalService.open(deleteopen, {backdrop: 'static'});
  }
  closePop(popclose) {
    this.modalReference.close();
  }

  getClientConfig() {
    this.configService.getClientConfig().subscribe(config => {
      this.config = config;
    }, err => {
      console.log(err);
    });
  }

  // getEnabledAssetsCoins() {
  //   this.configService.getEnabledAssetsCoins().subscribe(assetManagement => {
  //     this.assetManagement = assetManagement;
  //   }, err => {
  //     console.log('err', err);
  //   });
  // }

  enableWithdraw(index, status) {
    const active = (status ? false : true);
    this.assetManagement.assets[index].options.withdraw_enabled = active;
    this.configService.saveAssetManagement(this.assetManagement).then(savedAsset => {
      this.router.navigateByUrl('blank').then(() => {
        this.router.navigateByUrl('withdraw');
      });
    }, err => {
      console.log('err', err);
    });
  }
}
