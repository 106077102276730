import { Component, OnInit } from '@angular/core';
import firebase from 'firebase/app';

import { ConfigService } from '../services/config.service';
import { GlobalvariableProvider } from '../providers/globalprovider';
import { Router } from '@angular/router';
@Component({
  selector: 'app-coinmanagement',
  templateUrl: './coinmanagement.component.html',
  styleUrls: ['./coinmanagement.component.css']
})
export class CoinmanagementComponent implements OnInit {
  accessToken: string;
  supportedAssets: any;
  constructor(
    public configService: ConfigService,
    public global: GlobalvariableProvider,
    private router: Router,
  ) { }

  ngOnInit() {
    // this.getNcexConfig();
    firebase.auth().currentUser.getIdToken().then(authToken => {
      this.accessToken = authToken;
      // this.getSupportedAssets(this.accessToken);
    });
    if (!this.global.loggedin) {
      this.router.navigate(['/']);
    }
  }

  // getNcexConfig() {
  //   this.configService.getConfig().subscribe(config => {
  //     console.log(config);
  //   }, err => {
  //     console.log(err);
  //   })
  // }

  // getSupportedAssets(accessToken) {
  //   this.configService.getSupportedAssets(accessToken).subscribe(supportedAssets => {
  //     this.supportedAssets = supportedAssets['assets_supported'];
  //   }, err => {
  //     console.log('err', err);
  //   });
  // }
}
