import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule, Routes} from '@angular/router';
import { AppMaterialModule } from './app-material.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { NotifierModule } from 'angular-notifier';
import { NgxSpinnerModule } from 'ngx-spinner';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UsermanagementComponent } from './usermanagement/usermanagement.component';
import { CoinmanagementComponent } from './coinmanagement/coinmanagement.component';
import { NavigationComponent } from './navigation/navigation.component';
import { TradepairComponent } from './tradepair/tradepair.component';
import { DepositComponent } from './deposit/deposit.component';
import { WithdrawComponent } from './withdraw/withdraw.component';
import { GlobalvariableProvider } from './providers/globalprovider';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { SupportedassetsComponent } from './supportedassets/supportedassets.component';
import { DialogOverviewExampleDialog } from './dialog-overview-example/dialog-overview-example';
import { SystemconfigurationComponent } from './systemconfiguration/systemconfiguration.component';
import { EditConfigDialog } from './dialog-edit-config/dialog-edit-config';
import { MarketsComponent } from './markets/markets.component';
import { BuySellComponent } from './buysell/buysell.component';
import { SavingsComponent } from './savings/savings.component';
import { BurnIssueComponent } from './burn-issue/burn-issue.component';
import { DepositsWithdrawalsComponent } from './deposits-withdrawals/deposits-withdrawals.component';
import { BuyComponent } from './buy/buy.component';

import { ReplaceOpenPipe } from './pipes/replace-open.pipe';
import { AddBitPipe } from './pipes/add-bit.pipe';

const appRoutes: Routes = [
  {path: '', component: LoginComponent, pathMatch: 'full'},
  {path: 'dashboard', component: DashboardComponent},
  {path: 'usermanagement', component: UsermanagementComponent},
  {path: 'coinmanagement', component: CoinmanagementComponent},
  {path: 'tradepair', component: TradepairComponent},
  {path: 'deposit', component: DepositComponent},
  {path: 'withdraw', component: WithdrawComponent},
  {path: 'supported-assets', component: SupportedassetsComponent},
  {path: 'systemconfiguration', component: SystemconfigurationComponent},
  {path: 'markets', component: MarketsComponent},
  {path: 'buysell', component: BuySellComponent},
  {path: 'savings', component: SavingsComponent},
  {path: 'burn', component: BurnIssueComponent},
  {path: 'issue', component: BurnIssueComponent},
  {path: 'deposits-withdrawals', component: DepositsWithdrawalsComponent},
  {path: 'buy', component: BuyComponent},
  { path: '**', redirectTo: '' }
];

declare var require: any;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    UsermanagementComponent,
    CoinmanagementComponent,
    NavigationComponent,
    TradepairComponent,
    DepositComponent,
    WithdrawComponent,
    SupportedassetsComponent,
    ReplaceOpenPipe,
    AddBitPipe,
    SystemconfigurationComponent, 
    DialogOverviewExampleDialog,
    EditConfigDialog,
    MarketsComponent,
    BuySellComponent,
    SavingsComponent,
    BurnIssueComponent,
    DepositsWithdrawalsComponent,
	BuyComponent,
  ],
  imports: [
    AngularFireModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    BrowserModule, RouterModule.forRoot(
      appRoutes,
      { useHash: false }
    ),
    NgxSpinnerModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right',
          distance: 12
        },
        vertical: {
          position: 'top',
          distance: 12,
          gap: 10
        }
      }
    }),
    AppMaterialModule,
    NgbModule,
    BrowserAnimationsModule,
    HttpClientModule,
  ],
  entryComponents: [DialogOverviewExampleDialog, EditConfigDialog],
  providers: [AngularFirestoreModule, GlobalvariableProvider, { provide: SETTINGS, useValue: {} }],
  bootstrap: [AppComponent]
})
export class AppModule { }
