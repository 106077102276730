import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable, of } from 'rxjs';
import { switchMap} from 'rxjs/operators';
import { Users } from '../models/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ConfigService } from '../services/config.service';

@Injectable({
  providedIn: 'root'
})

export class UsersService {
  private userDoc: AngularFirestoreDocument<Users>;
  user: any;

  
  constructor(
    private db: AngularFirestore,
    public afAuth: AngularFireAuth,
    public afStorage: AngularFireStorage,
    public configService: ConfigService,
	private router: Router,
  ) {

      
  }

  getAllUsers(markets?, defCurr?) {
    // return this.db.collection('Users').valueChanges();
    return this.db.collection('Users').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        data.uid = id;
        // To get user balance
        let userCurrency; // Set user currency to EUR if not exist
        
        if (defCurr) {
          userCurrency = defCurr;
        } else {
          userCurrency = (data.currency ? data.currency : 'EUR'); // Set user currency to EUR if not exist
        }
        this.getThisUsersBalance(id, userCurrency, markets).subscribe(uBalance => {
          data.balance = uBalance;
          // Sum of all balance
          const totalBalance: number = uBalance.reduce( (sa: number, b) => sa + b.totalBalance.balance, 0);
          data.totalBalance = totalBalance;
        });
        // To get user savings
        this.getThisUsersSavings(id, userCurrency, markets).subscribe((udata: any) => {
          data.savings = udata;
          // Sum of all savings
          const totalSavings: number = udata.reduce( (sa: number, b) => sa + b.totalSavings.balance, 0);
          data.totalSavings = totalSavings;
        });
        return data;
      }))
    );

  }

  getUsersSavings() {
    const buySell = this.db.collection(`Users`).snapshotChanges().pipe(
      map(changes => changes.map(a => {
        const data = a.payload.doc.data() as any;
        data.udid = a.payload.doc.id;
        this.getThisUsersSavings(data.udid).subscribe(udata => {
          data.savedcoins = udata;
        });
        return data;
      }))
    );
    return buySell;
  }

  getCurrentUser() {
    return new Promise<any>((resolve, reject) => {
      this.user = this.afAuth.authState.pipe(
        switchMap(user => {
          if (user) {
            return this.db.doc<Users>(`Users/${user.uid}`).valueChanges();
          } else {
            return of(null);
          }
        })
      );
      if (this.user) {
        this.user.subscribe(user => {
          resolve(user);
        }, err => reject(err));
      } else {
        reject('No user logged in');
      }
    });
  }
  
  authAdminUser() {
    return new Promise<any>((resolve, reject) => {
      this.user = this.afAuth.authState.pipe(
        switchMap(user => {
          if (user) {
            return this.db.doc<Users>(`Users/${user.uid}`).valueChanges();
          } else {
            return of(null);
          }
        })
      );
      if (this.user) {
        this.user.subscribe(user => {
          resolve(user);
        }, err => reject(err));
      } else {
        reject('No user logged in');
      }
    });
  }
  
  getConfig() {
    return this.db.collection('Config').valueChanges();
  }
  updateUser(user: Users) {
    this.userDoc = this.db.doc<Users>(`Users/${user.uid}`);
    return this.userDoc.update(user);
  }

  deleteUser(uId) {
    this.userDoc = this.db.doc<Users>(`Users/${uId}`);
    return this.userDoc.delete();
  }

  downloadFile(data, filename = 'data') {
    const csvData = this.ConvertToCSV(data, ['firstName','lastName', 'accountName', 'mobileNo', 'mobileNoVerified', 'email', 'emailVerified', 'currency', 'language', 'status', 'uid', 'Widgets', 'roles']);
    const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    this.afStorage.upload('admins/exports/users.csv', blob);
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {  // if Safari open in new window to save file with random filename.
        dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList) {
      let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      let str = '';
      let row = 'S.No,';

      for (let index in headerList) {
            row += headerList[index] + ',';
      }
      row = row.slice(0, -1);
      str += row + '\r\n';
      for (let i = 0; i < array.length; i++) {
          let line = (i+1)+'';
          for (let index in headerList) {
              let head = headerList[index];
              
              if ((head === 'Widgets' || head === 'roles') &&  typeof(array[i][head]) == 'object') {
                //console.log(head +' = head, '+ typeof(array[i][head]) + ' = typeof(array[i][head]) + ');
                var widData = JSON.stringify(array[i][head]);
                widData = widData.replace(/,/g, '; ');
                line += ',' + widData;
              } else {
                line += ',' + array[i][head];
              }
              
          }
          str += line + '\r\n';
      }
      return str;
  }
  getThisUser(uid) {
    return this.db.doc(`Users/${uid}`).valueChanges();
  }

  getThisUsersSavings(uid, userCurrency?, markets?) {
    // return this.db.doc(`Users/${uid}`).collection(`savings`).valueChanges();
    const usersAllSavings = this.db.doc(`Users/${uid}`).collection(`savings`, ref => ref.orderBy('updated', 'desc')).snapshotChanges()
    .pipe(
      map(changes => changes.map(a => {
        const data = a.payload.doc.data() as any;
        data.totalSavings = {balance: 0, symbol: userCurrency};
        if (markets) {
          const exchangeRates = markets;
          const singleExchange = exchangeRates.filter(exchange => {
            const pair = exchange.pair;
            const savingsAsset = Object.keys(data.balance)[0];
            const savingsPair = savingsAsset + ':' + userCurrency;
            if (pair === savingsPair) {
              return exchange;
            }
          });
          if (singleExchange.length > 0) {
            const savingsAmount: any = Object.values(data.balance)[0];
            data.totalSavings = {balance: (singleExchange[0].price * savingsAmount), symbol: userCurrency};
          }
        }
        // console.log('data', data.balance);
        data.bdid = a.payload.doc.id;
        return data;
      }))
    );
    return usersAllSavings;
  }

  getThisUsersBalance(uid, userCurrency?, markets?) {
    const usersAllBalance = this.db.doc(`Users/${uid}`).collection(`balance`).snapshotChanges()
    .pipe(
      map(changes => changes.map(a => {
        const data = a.payload.doc.data() as any;
        data.bdid = a.payload.doc.id;
        data.totalBalance = {balance: 0, symbol: userCurrency};
        if (markets) {
          const exchangeRates = markets;
          const singleExchange = exchangeRates.filter(exchange => {
            const pair = exchange.pair;
            const balanceAsset = data.bdid;
            const balancePair = balanceAsset + ':' + userCurrency;
            if (pair === balancePair) {
              return exchange;
            }
          });
          if (singleExchange.length > 0) {
            data.totalBalance = {balance: (singleExchange[0].price * data.amount), symbol: userCurrency};
          }
        }
        return data;
      }))
    );
    return usersAllBalance;
  }

  updateUserBalance(userData, status) {
    return new Promise<any>((resolve, reject) => {
      resolve(this.db.doc(`Users/${userData.udid}/savings/${userData.bdid}`).update({'status': status}));
    });
  }

  addUserBalance(userData, balanceData) {
    return new Promise<any>((resolve, reject) => {
      resolve(this.db.doc(`Users/${userData.uid}`).collection(`savings`).add(balanceData));
    });
  }
  
  
}
