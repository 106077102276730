import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { UsersService } from './users.service';

import { Observable, combineLatest, from, of } from 'rxjs'
import { map, switchMap, filter, flatMap } from 'rxjs/operators'
import { uniq, flatten } from 'lodash'
import 'rxjs/add/operator/merge';
@Injectable({
  providedIn: 'root'
})
export class BuysellService {
  
  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
    public userService: UsersService,
  ) { }
  
  getUser(uid) {
    return new Promise<any>((resolve, reject) => {
      this.userService.getThisUser(uid).subscribe(userInfo => {
        resolve(userInfo);
      });
    });
  }
  getUsersBuySell() {
    const buySell = this.db.collection(`Buy_Sell`).snapshotChanges().pipe(
      map(changes => changes.map(a => {
        const data = a.payload.doc.data() as any;
        data.bsid = data.uid;
        this.getUser(data.bsid).then(udata => {
          data.userInfo = udata;
        });
        return data;
      }))
    );
    return buySell;
  }
}
