import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';
import { ConfigService } from '../services/config.service';
import { AngularFireAuth} from '@angular/fire/auth';
import { GlobalvariableProvider } from '../providers/globalprovider';
import { Router } from '@angular/router';
import  $ from 'jquery';
import { MarketsService } from '../services/markets.service';
import { BuysellService } from '../services/buysell.service';
import { Apis } from 'bitsharesjs-ws';
import { BitshareService } from '../services/bitshare.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  allUsers: any;
  activeUser: any;
  inactiveUser: any;
  config: any;
  assetManagement: any;
  withdrawEnabled: any;
  depositEnabled: any;
  tradeEnabled: any;
  enabledCoins: any;
  totalAsset: number;
  balanceCur:string = 'EUR';
  balanceShow:boolean = false;
  totalBalance: number = 0;
  totalInterest: number = 0;
  totalPurchased: number;
  exchangeRates: any;
  usersBuySell: any;
  
  constructor(
    private userService: UsersService,
    public configService: ConfigService,
    public afAuth: AngularFireAuth,
    public global: GlobalvariableProvider,
    private router: Router,
    private marketsService: MarketsService,
    public buysellService: BuysellService,
    private bitshareService: BitshareService,
  ) {}

  ngOnInit() {
    if (Apis.instance().chain_id) {
      
    } else {     
      this.bitshareService.bsConnect().then((connRes:any) => {	
	    console.log('WSS connection res', connRes);
        if(connRes.WSSConnection === true) {	
          console.log('WSS Connection failed');	
        } 
      });
    }
    this.getBitsharesMarket();
    this.getClientConfig();
    // this.getAssetManagement();
    // this.getEnabledAssetsCoins();
    this.getUsersBuySell();
    if (!this.global.loggedin) {
      this.router.navigate(['/']);
    }
  }
  ngAfterViewInit() {
    
    setTimeout(() => {
      if ($('#menu_sidenav_id').css("visibility") == 'hidden') {
        $('#menu_toolbar_id').trigger('click');
      }
      this.updateUsersBalances();
    }, 4000);
  }
  
  updateUsersBalances() {
    //usersBalance
    if (this.allUsers) {
      this.allUsers.forEach((udata, ind) => {
        if(udata.totalBalance) {
          this.totalBalance = this.totalBalance + udata.totalBalance;
        }
        if(udata.savings && udata.savings.length > 0) {
          udata.savings.forEach((usdata, ind) => {
            if (usdata.type == 'Interest' && usdata.status == 'Accepted'){
              this.totalInterest = this.totalInterest + usdata.totalSavings.balance;
            }
          });
        }
      });
      //get market price for EUR:USD
      const exchangeEURtoUSD = this.exchangeRates.filter(exchange => {
        const pair = exchange.pair;
        const savingsPair = 'EUR:USD';
        if (pair === savingsPair) {
          return exchange;
        }
      });
      if (exchangeEURtoUSD.length > 0) {
        const EURvalforUSD = exchangeEURtoUSD[0].price;
        let purUSD:number = 0;
        let purEUR:number = 0;
        this.usersBuySell.forEach((updata, ind) => {
          if (updata.type == 'BUY') {
            if (updata.paying_currency == 'EUR') {
              purEUR = +updata.paying_currency_value + purEUR;
            } else {
              purUSD = +updata.paying_currency_value + purUSD;
            }
          }
        });

        if (purUSD > 0) {
          purUSD = purUSD * EURvalforUSD; //convert total of USD to EUR
        }
        this.totalPurchased = purUSD + purEUR;
      }
      this.balanceShow = true;
    }
  }
  
  getBitsharesMarket() {
    this.marketsService.getBitSharesMarket().subscribe(markets => {
      this.exchangeRates = markets;
      this.getAllUsers(markets);
    }, err => {
      console.log(err);
    });
  }
  
   getUsersBuySell() {
    this.buysellService.getUsersBuySell().subscribe((buysell: any) => {
      this.usersBuySell = buysell;
    }, err => {
      console.log(err);
    });
    
  }
  
  getAllUsers(markets) {
    this.userService.getAllUsers(markets, this.balanceCur).subscribe(users => {
      this.allUsers = users;
      this.activeUser = users.filter((user: any) => user.status === true);
      this.inactiveUser = users.filter((user: any) => user.status === false);
    }, error => {
      console.log('error', error);
    });
  }

  // getAssetManagement() {
  //   this.configService.getAssetManagement().subscribe(assetManagement => {
  //     this.assetManagement = assetManagement;
  //   }, err => {
  //     console.log('err', err);
  //   });
  // }
  //  getEnabledAssetsCoins() {
  //   this.configService.getEnabledAssetsCoins().subscribe((enabledCoins: any) => {
  //     this.enabledCoins = enabledCoins;
  //     if (this.enabledCoins) {
  //         this.depositEnabled = this.enabledCoins.assets.filter((options: any) => {
  //           if(options.options.deposit_enabled) {
  //             options.options.deposit_enabled == true
  //           }
  //         });
  //         this.withdrawEnabled = this.enabledCoins.assets.filter((options: any) => options.options.withdraw_enabled == true);
  //         this.tradeEnabled = this.enabledCoins.assets.filter((options: any) => options.options.exchange_enabled == true);
  //     } else {
  //       this.depositEnabled = true;
  //       this.withdrawEnabled = true;
  //       this.tradeEnabled = true;
  //     }
  //   }, err => {
  //     console.log('err', err);
  //   });
  // }

  getClientConfig() {
    this.configService.getClientConfig().subscribe(config => {
      this.config = config;
    }, err => {
      console.log(err);
    });
  }
}
