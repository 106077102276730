import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask  } from '@angular/fire/storage';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServerConfigService } from '../services/serverconfig.service';
// import { FaucetConfigService } from '../services/serverconfig.service';


export interface ConfigData {
  systemdata: {
    name: string;
    value: string;
    table: string;
  }
}

@Component({
  selector: 'dialog-edit-config',
  templateUrl: './dialog-edit-config.html',
  styleUrls: ['./dialog-edit-config.css']
})
export class EditConfigDialog {
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  fieldType: string;
  booleanFlags: boolean[] = [true, false];
  textValue: string;
  numberValue: number;
  booleanValue: string;
  loader: string;
  tableName: string;
  
  constructor(
    public dialogRef: MatDialogRef<EditConfigDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ConfigData,
    private afStorage: AngularFireStorage,
    private serverConfig: ServerConfigService,
    // private faucetConfig: FaucetConfigService,
    private router: Router,
    private spinner: NgxSpinnerService,
  ) { 
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  updateConfigData(): void {
    const testTemp:any = []; 
    let keyValue: any = '';
    if (this.fieldType === 'Number') {
      keyValue = this.numberValue;
    }
     if (this.fieldType === 'String') {
      keyValue = this.textValue;
    }
    if (this.fieldType === 'Boolean') {
      keyValue = this.booleanValue;
    }
    testTemp.push({'key': this.data.systemdata.name, 'value':keyValue, 'valueType': this.fieldType});
    if (this.tableName === 'Server') {
      this.serverConfig.updateServerConfig(testTemp).then(objectData => {
        if (objectData.message == 'success') {
          this.loader = 'Updated, please wait';
          //this.router.navigate(['/systemconfiguration']);
          setTimeout(() => {
            this.dialogRef.close();
          }, 2000);
        }
      });
    }
    if (this.tableName === 'Client') {
      this.serverConfig.updateClientConfig(testTemp).then(objectData => {
        if (objectData.message == 'success') {
          this.loader = 'Updated, please wait';
          //this.router.navigate(['/systemconfiguration']);
          setTimeout(() => {
            this.dialogRef.close();
          }, 2000);
        }
      });
    }
    if (this.tableName === 'Faucet') {
      this.serverConfig.updateFaucetConfig(testTemp).then(objectData => {
        if (objectData.message == 'success') {
          this.loader = 'Updated, please wait';
          //this.router.navigate(['/systemconfiguration']);
          setTimeout(() => {
            this.dialogRef.close();
          }, 2000);
        }
      });
    }
  }
  
}

