import { Component, OnInit, Inject } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SavingsService } from '../services/savings.service';
import { UsersService } from '../services/users.service';


@Component({
  selector: 'app-savings',
  templateUrl: './savings.component.html',
  styleUrls: ['./savings.component.scss']
})
export class SavingsComponent implements OnInit {
  usersSavings: any;
  usersBalance: any = [];
  
  constructor(
    public savingsService: SavingsService,
    public userService: UsersService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.getUsersSavings();
  }
  
  getUsersSavings() {
    this.spinner.show();
    this.userService.getUsersSavings().subscribe((savings: any) => {
      if (savings) {
        this.usersSavings = savings;
        setTimeout(() => {
          this.usersBalance.length = 0;
          savings.forEach(b => {
            if (b.savedcoins.length > 0) {
              b.savedcoins.forEach(currBal => {
                //if (currBal.balance.length > 0) {
                const coinBal = currBal['balance'];
                Object.keys(coinBal).forEach(key => {
                  this.usersBalance.push({'udid': b.udid, 'firstName' : b.firstName, 'lastName' : b.lastName, 'email' : b.email, 'bdid' : currBal.bdid, 'currency' : key , 'amount' : coinBal[key], 'status' : currBal.status, 'updateat' : currBal.updated, 'type': currBal.type}); 
                });
                //}  
              });

            }
          });
          if (this.usersBalance) {
            this.usersBalance.sort((a, b) => {
              return compare(a.updateat, b.updateat, false);
            });
            this.spinner.hide();
          }
        }, 2000);
        
      }
    }, err => {
      console.log(err);
      this.spinner.hide();
    });
  }
  
  acceptBalance(userData) {
    this.spinner.show();
    this.userService.updateUserBalance(userData, 'Accepted').then(success => {
      this.getUsersSavings();
    });
  }
  
  declineBalance(userData) {
    this.spinner.show();
    this.userService.updateUserBalance(userData, 'Declined').then(success => {
      this.getUsersSavings();
    });
  }
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}