import { Injectable } from '@angular/core';
import firebase  from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { first, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,
  ) {
  }

  doLogin(login) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.signInWithEmailAndPassword(login.email, login.password).then(res => {
        resolve(res);
      }, err => reject(err));
    });
  }
  doLogout() {
    return new Promise((resolve, reject) => {
      if (this.afAuth.currentUser) {
        this.afAuth.signOut();
        resolve("");
      } else {
        reject();
      }
    });
  }
  isLoggedIn() {
    return this.afAuth.authState.pipe(first());
  }
  isUserLoggedIn() {
    return this.isLoggedIn().pipe(
      tap(user => {
        if (user) {
          return user;
        } else {
          return true;
        }
      })
    );
  }

  // disableAccount(uId) {
  //   admin.auth().updateUser(uId, {
  //     disabled: true
  //   });
  // }
  
  doFacebookLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.signInWithPopup(provider).then(res => {
        resolve(res);
      }, err => {
        // console.log(err);
        reject(err);
      });
    });
  }

  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.afAuth.signInWithPopup(provider).then(res => {
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }
  
  doTwitterLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.TwitterAuthProvider();
      this.afAuth.signInWithPopup(provider).then(res => {
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }
}
