import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { FIREBASE_OPTIONS } from '@angular/fire';
import { AppModule } from './app/app.module';

enableProdMode();

/*
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
*/
function loadConfig() {
  return fetch('/__/firebase/init.json')
      .then(response => response.json());
}

(async () => {
  const config = await loadConfig();
  
  platformBrowserDynamic([{ provide: FIREBASE_OPTIONS, useValue: config }])
     .bootstrapModule(AppModule)
     .catch(err => console.error(err));
  /*
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
  */
})();
