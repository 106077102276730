import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  assetMgmtDoc: AngularFirestoreDocument<any>;
  public domainsAPIURL: string;
  public apiUrl: string;
  public hostUrl: string;
  public BASE_ASSET: string;
  public prefixAsset: string;

  constructor(
    private db: AngularFirestore,
    public afs: AngularFirestore,
    private http: HttpClient,
	private router: Router,
  ) {
	
	const BASEURL_PROP = this.router;
    let BASEURL = BASEURL_PROP['location']['_platformLocation']['location']['origin'];
    console.log('BASEURL', BASEURL);
    if(BASEURL_PROP['location']['_platformLocation']['location']['hostname'] == 'localhost') {
      BASEURL = 'https://api.testnet.blockbasis.com';
    } else {
      BASEURL = BASEURL.replace(/admin/g, 'api');
    }
    /*
	let BASEURL: string;
	if(window.location.hostname == 'localhost') {
      BASEURL = 'https://api.testnet.blockbasis.com';
    } else {
	  BASEURL = window.location.hostname; 
      BASEURL = BASEURL.replace(/app/g, 'api');
    }
  */
 console.log('BASEURL', BASEURL);
  console.log('BASEURL_PROP', BASEURL_PROP);
	//console.log('window location', window.location.hostname);
	//console.log('BASEURL', BASEURL);
	//let BASEURL = 'https://api.testnet.blockbasis.com';
    this.domainsAPIURL = BASEURL;
	  this.getSetURL();
  }

  getClientConfig() {
    return this.db.collection('Config').doc('Client').valueChanges();
  }
  getClientFaucet() {
    return this.db.collection('Config').doc('Faucet').valueChanges();
  }
  // getAssetManagement() {
  //   return this.db.collection('Config').doc('Asset_Management').valueChanges();
  // }
  // getEnabledAssetsCoins() {
  //   return this.db.collection('Config').doc('Enabled_Assets').valueChanges();
  // }
  saveAssetManagement(asset) {
    return new Promise<any>((resolve, reject) => {
      this.assetMgmtDoc = this.afs.doc(`/Config/Enabled_Assets`);
      this.assetMgmtDoc.update(asset).then(res => {
        resolve(res);
      }, err => {
        reject(err);
        console.log('update ERR', err);
      });
    });
  }

  // getSupportedAssets(accessToken) {
    // const headers = new HttpHeaders({'Authorization': 'Bearer ' + accessToken });
    // return this.http.get(this.apiUrl + '/assets/supported', { headers });
    // return this.getAssetManagement();
  // }
  
  getBaseAsset () {
    return new Promise<any>((resolve, reject) => {
      this.getGateway().subscribe((coreAssetBS: any) => {
        console.log('coreAssetBS', coreAssetBS);

        resolve(coreAssetBS['BASE_ASSET']);
      });
    });
  }
  
  getGateway() {
    if (this.apiUrl) {
        return this.http.get(this.apiUrl + '/gateway');
    } else {
       this.getSetURL().then(configData => {
         return this.http.get(this.apiUrl + '/gateway');
       });
    }
  }
  
  getClientConfigAPI() {
    console.log('this.domainsAPIURL', this.domainsAPIURL);
    return this.http.get(this.domainsAPIURL + '/client/config');
  }
  
  getSetURL() {
    return new Promise<any>((resolve, reject) => {
      this.getClientConfigAPI().subscribe(configData => {
        console.log('configData', configData);
        this.apiUrl = 'https://' + configData['API_HOSTNAME'];
        this.hostUrl = 'https://' + configData['PRO_HOSTNAME'];
		this.prefixAsset = configData['GATEWAY_BASE_ASSET'];
      }, err => {
        console.log('err', err);
      });
    });
  } 
  
  getActiveWallets() {
    if (this.apiUrl) {
        return this.http.get(this.apiUrl + '/gateway/active-wallets');
    } else {
       this.getSetURL().then(configData => {
         return this.http.get(this.apiUrl + '/gateway/active-wallets');
       });
    }
  }
  
}
