import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { AngularFireAuth} from '@angular/fire/auth';

import { AuthService } from '../services/auth.service';
import { GlobalvariableProvider } from '../providers/globalprovider';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  id: any;
  loggedin = true;
  constructor(
    private authService: AuthService,
    private router: Router,
    public afAuth: AngularFireAuth,
    public global: GlobalvariableProvider
  ) { }

  ngOnInit() {
    this.authService.isUserLoggedIn().subscribe(user => {
      if (user) {
        this.afAuth.currentUser.then(authToken => {
          this.global.loggedin = true;
        });
      }
    });
  }
  logout(): void {
    this.authService.doLogout().then(success => {
      this.router.navigate(['/']);
      this.global.loggedin = false;
    }).catch(error => {
      console.log(error);
    });
  }
}
