import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class ServerConfigService {


  constructor(
    private db: AngularFirestore,
    public afs: AngularFirestore,
    private http: HttpClient
  ) {}

  getServerConfig() {
    return this.db.collection('Config').doc('Server').valueChanges();
  }
  
  getFaucetConfig() {
    return this.db.collection('Config').doc('Faucet').valueChanges();
  }
  getClientConfig() {
    return this.db.collection('Config').doc('Client').valueChanges();
  }
  
  updateClientConfig(serverData) {
    return new Promise<any>((resolve, reject) => {
      const storageRef = this.afs.doc(`/Config/Client`);

      const staticKey = serverData[0].key;
      const staticValue = serverData[0].value;
    
      var updateData = {
        [staticKey]: staticValue,
      }
      storageRef.update(updateData).then(res => {
        resolve({'message':'success'});
      }, err => {
        reject(err);
        console.log('update server ERR', err);
      });
    });
  }
  
  updateServerConfig(serverData) {
    return new Promise<any>((resolve, reject) => {
      const storageRef = this.afs.doc(`/Config/Server`);

      const staticKey = serverData[0].key;
      let staticValue = serverData[0].value;
      /*if (serverData[0].valueType === 'Boolean') {
        staticValue = staticValue.toLowerCase() == 'true'
      }*/
      
      var updateData = {
        [staticKey]: staticValue
      }
      storageRef.update(updateData).then(res => {
        resolve({'message':'success'});
      }, err => {
        reject(err);
        console.log('update server ERR', err);
      });
    });
    
  }
  updateFaucetConfig(faucetData) {
    return new Promise<any>((resolve, reject) => {
      const storageRef = this.afs.doc(`/Config/Faucet`);

      const staticKey = faucetData[0].key;
      let staticValue = faucetData[0].value;
      /*if (serverData[0].valueType === 'Boolean') {
        staticValue = staticValue.toLowerCase() == 'true'
      }*/
      
      var updateData = {
        [staticKey]: staticValue
      }
      storageRef.update(updateData).then(res => {
        resolve({'message':'success'});
      }, err => {
        reject(err);
        console.log('update faucet ERR', err);
      });
    });
    
  }
}
