import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceOpen'
})
export class ReplaceOpenPipe implements PipeTransform {

  transform(value: string): string {
    if (value) {
      return value.replace(/open./g, '').replace(/OPEN./g, '');
    } else {
      return value;
    }
  }

}
