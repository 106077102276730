import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalvariableProvider } from '../providers/globalprovider';
import { Router } from '@angular/router';

@Component({
  selector: 'app-buy',
  templateUrl: './buy.component.html',
  styleUrls: ['./buy.component.scss']
})
export class BuyComponent implements OnInit {
  public moonpayApiUrl:string = 'https://api.moonpay.com';
  public allMPTrans: any = [];
  public showMPTrans: boolean = false;
  private moonpaySecretKey = 'sk_live_zy9pJeWbFr8CNTxGhKvtgHjuOcdn'; //sk_test_cILqDC4rs1hLFkmQPU4fC0a540aypu
  private moonpayPriKey = 'pk_live_o6IoqSubJHv16Hkmxk1ULlcqoHNZ3'; //pk_test_hvCod142wP9zgevCjoELcJgb7DHlz6
  public moonpayCurrencies: any = [];
  
  constructor(
     private http: HttpClient,
	 public global: GlobalvariableProvider,
	 private router: Router,
  ) { }

  ngOnInit() {
     if (!this.global.loggedin) {
      this.router.navigate(['/']);
    }
	this.getMoonPayCurrencies().subscribe((mpcurr: any) => {
	  console.log('mpcurr', mpcurr);
	  this.moonpayCurrencies = mpcurr;
	});
	
	this.getMoonpayTrans().subscribe((mptrans: any) => {
	  console.log('mptrans', mptrans);
	  this.allMPTrans = mptrans;
	  this.showMPTrans = true;
	});
  }
  
  getMoonpayTrans() {
    //sk_live_zy9pJeWbFr8CNTxGhKvtgHjuOcdn sk_test_cILqDC4rs1hLFkmQPU4fC0a540aypu
	const headers = new HttpHeaders({ Authorization: 'Api-Key ' + this.moonpaySecretKey});
    return this.http.get(this.moonpayApiUrl + '/v1/transactions', { headers });
  }
  
  getMoonPayCurrencies() {
    
	return this.http.get(this.moonpayApiUrl + '/v3/currencies?apiKey=' + this.moonpayPriKey);
  }
  
  getCurrCode(cid) {
    /*
    let currInfo;
	let doloop = true;
    this.moonpayCurrencies.forEach((cinfo, cin) => {
	  
	  if(doloop) {
	    if(cinfo.id == cid) {
		  doloop = false;
		  currInfo = cinfo;
		}
	  }
	  
	});
	console.log('currInfo', currInfo);
	return currInfo.name;
	*/
	
	let currCode = this.moonpayCurrencies.filter(function(cinfo){
	    if(cinfo.id == cid) {
		  console.log('cinfo', cinfo);
		  return cinfo;
		}
	});
	//console.log('currCode', currCode);
	if(currCode[0]) {
	  return currCode[0]['name'];
	}
	
  }
  
}
