import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifierService } from 'angular-notifier';

import { ConfigService } from '../services/config.service';
import { UsersService } from '../services/users.service';
import { GlobalvariableProvider } from '../providers/globalprovider';

@Component({
  selector: 'app-tradepair',
  templateUrl: './tradepair.component.html',
  styleUrls: ['./tradepair.component.css']
})
export class TradepairComponent implements OnInit {
  panelOpenState = false;
  modalReference: any;
  config: any;
  assetManagement: any;
  trade: any;
  user: any;
  selectedPair: string;
  accessToken: string;
  supportedAssets: any;
  filteredAssets: any;
  hide = false;
  deleteTrade: any;
  deleteTradeIndex: any;
  deletePairItem: any;
  deletePairItemIndex: any;
  private readonly notifier: NotifierService;
  constructor(
    private modalService: NgbModal,
    public configService: ConfigService,
    public userService: UsersService,
    private router: Router,
    private spinner: NgxSpinnerService,
    public global: GlobalvariableProvider,
    notifierService: NotifierService
  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.spinner.show();
    // this.getEnabledAssetsCoins();
    this.userService.getCurrentUser().then(res => {
      if (res) {
        this.user = res;
        firebase.auth().currentUser.getIdToken().then(authToken => {
          this.accessToken = authToken;
          // this.getSupportedAssets(this.accessToken);
        });
      }
    });
    if (!this.global.loggedin) {
      this.router.navigate(['/']);
    }
  }
  editPop(editopen) {
    this.modalReference = this.modalService.open(editopen, {backdrop: 'static'});
  }
  addPop(addopen) {
    this.modalReference = this.modalService.open(addopen, {backdrop: 'static'});
  }
  openAssetsNewPair(addNewPair, trade) {
    this.filteredAssets = this.supportedAssets; // by default set the same asset of supportedAssets
    this.removeAddedAssets(trade);
    this.trade = trade;
    this.modalReference = this.modalService.open(addNewPair, {backdrop: 'static'});
  }
  removeAddedAssets(trade) {

    let uniqueAsset: any = [];
    this.filteredAssets.forEach((aitem, aind) => {
      let uniqFlag = true;
      if (aitem === trade.asset){
        uniqFlag = false;
      } else if(trade.options.trading_pairs && trade.options.trading_pairs.length > 0) {
        trade.options.trading_pairs.forEach((pitem, pind) => {
          if (aitem === pitem) {
            uniqFlag = false;
          }
        });
      }
      if (uniqFlag === true) {
        uniqueAsset.push(aitem);
      }
    });
    this.filteredAssets = null;
    this.filteredAssets = uniqueAsset;
  }
  addAssetsNewPair(selectedPair) {
    if (selectedPair) {
      this.spinner.show();
      let assetExist = false;
      let nullExist = false;
      for (const asset of this.assetManagement.assets) {
        if (asset.asset === this.trade.asset) {
          if (asset.options.trading_pairs !== null) {
            nullExist = false;
            for (const pair of asset.options.trading_pairs) {
              if (pair === selectedPair) {
                assetExist = true;
              } else {
                assetExist = false;
              }
            }
          } else {
            nullExist = true;
          }
        }
      }
      if (!assetExist && !nullExist) {
        this.trade.options.trading_pairs.push(selectedPair);
        this.saveAssetToFirebase(this.assetManagement);
      }
      if (nullExist) {
        this.trade.options.trading_pairs = [selectedPair];
        this.saveAssetToFirebase(this.assetManagement);
      }
    }
  }

  deletePop(deleteopen, trade, tradeIndex, item, itemIndex) {
    this.deleteTrade = trade;
    this.deleteTradeIndex = tradeIndex;
    this.deletePairItem = item;
    this.deletePairItemIndex = itemIndex;
    this.modalReference = this.modalService.open(deleteopen, {backdrop: 'static'});
  }

  deleteTradePair() {
    const deletePair = this.assetManagement.assets[this.deleteTradeIndex].options.trading_pairs.indexOf(this.deletePairItem);
    if (deletePair > -1) {
      this.assetManagement.assets[this.deleteTradeIndex].options.trading_pairs.splice(deletePair, 1);
    }
    this.configService.saveAssetManagement(this.assetManagement).then(savedAsset => {
      this.spinner.hide();
      this.notifier.notify('success', this.deletePairItem + ' deleted from trading pair' );
      this.modalReference.close();
      this.router.navigateByUrl('blank').then(() => {
        this.router.navigateByUrl('tradepair');
      });
    }, err => {
      console.log('err', err);
    });
  }
  closePop(popclose) {
    this.modalReference.close();
  }

  saveAssetToFirebase(assetManagement) {
    this.configService.saveAssetManagement(assetManagement).then(savedAsset => {
      this.modalReference.close();
      this.spinner.hide();
      this.notifier.notify( 'success', 'Trading pairs has been added' );
      this.router.navigateByUrl('blank').then(() => {
        this.router.navigateByUrl('tradepair');
      });
    }, err => {
      console.log('err', err);
    });
  }

  // getSupportedAssets(accessToken) {
  //   this.configService.getSupportedAssets(accessToken).subscribe(supportedAssets => {
  //     this.supportedAssets = supportedAssets['assets_supported'];
  //     this.filteredAssets = this.supportedAssets;
  //     this.spinner.hide();
  //   }, err => {
  //     console.log('err', err);
  //   });
  // }
  // getEnabledAssetsCoins() {
  //   this.configService.getEnabledAssetsCoins().subscribe(assetManagement => {
  //     this.assetManagement = assetManagement;
  //   }, err => {
  //     console.log('err', err);
  //   });
  // }

  enableTrade(index, status) {
    this.spinner.show();
    const active = (status ? false : true);
    const message = (status ? 'Asset disabled successfully!' : 'Asset enabled successfully!');
    const style = (status ? 'error' : 'success');
    this.assetManagement.assets[index].options.exchange_enabled = active;
    this.configService.saveAssetManagement(this.assetManagement).then(savedAsset => {
      this.spinner.hide();
      this.notifier.notify( style, message );
      this.router.navigateByUrl('blank').then(() => {
        this.router.navigateByUrl('tradepair');
      });
    }, err => {
      console.log('err', err);
    });
  }
}
