import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '../services/users.service';
import { ConfigService } from '../services/config.service';
import { AngularFireAuth} from '@angular/fire/auth';
import { GlobalvariableProvider } from '../providers/globalprovider';
import { Router } from '@angular/router';
import  $ from 'jquery';
import { Apis } from 'bitsharesjs-ws';
import { BitshareService } from '../services/bitshare.service';

@Component({
  selector: 'app-deposits-withdrawals',
  templateUrl: './deposits-withdrawals.component.html',
  styleUrls: ['./deposits-withdrawals.component.scss']
})
export class DepositsWithdrawalsComponent implements OnInit {
  public allWithdrawals: any = [];
  public getAssetsInfoFlg: boolean = false;
  public toGetAddress: boolean = false;
  public BSBalance: any;
  public bitshareAssetInfo: Array<any> = [];
  public allBSAssets: any = [];
  public BASE_ASSET: string;
  public GET_BASE_CONFIG: boolean = false;
  public allFSTransactions: any;
  public modalReference: any;
  public transId: string;
  public wdUpdateRes: string;
  
  constructor(
    private userService: UsersService,
    public configService: ConfigService,
    public afAuth: AngularFireAuth,
    public global: GlobalvariableProvider,
    private router: Router,
    private bitshareService: BitshareService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    if (!this.global.loggedin) {
      this.router.navigate(['/']);
    }
   
  }
  
  ngAfterViewInit() {
    if (Apis.instance().chain_id) {
      this.getAllBSAssets();
      this.initPage();
    } else {     
      this.bitshareService.bsConnect().then((connRes:any) => {  
        console.log('WSS connection res', connRes);
        this.getAllBSAssets();
        this.initPage();
                
      });
    }
  }

  initPage() {
    if (localStorage.getItem('BASE_ASSET')) {
        this.BASE_ASSET = localStorage.getItem('BASE_ASSET');
        this.GET_BASE_CONFIG = true;
        setTimeout(() => {
          this.getAllFSTransactions();
          this.getPageBasics();
        }, 1500);
    } else {
        this.bitshareService.getBaseAsset().then(BASE_ASSET => {
          localStorage.setItem('BASE_ASSET', BASE_ASSET);
          this.BASE_ASSET = BASE_ASSET;
          this.GET_BASE_CONFIG = true;
          this.getAllFSTransactions();
          this.getPageBasics();
        })
    }
  }
  
  getPageBasics() {
    this.bitshareService.getRecentHistory('block-basis').then((withdrawals: any) => {
      console.log('BB ALL withdrawals ', withdrawals);
      //this.allWithdrawals = withdrawals;
      setTimeout(() => {
          withdrawals.forEach((wdk, wdi) => {
            let foundRes: boolean = false;
            this.allFSTransactions.forEach((trans, tid) => {
              console.log(wdk.Id + ' == ' + trans.id);
              if(wdk.Id == trans.id && foundRes == false) {
                  foundRes = true;
                  withdrawals[wdi].transFrom = trans.from;
                  withdrawals[wdi].transFromAlias = trans.from_alias;
                  withdrawals[wdi].transStatus = trans.status;
                  this.allWithdrawals.push(withdrawals[wdi]);
              }
            });
          });
          console.log('this.allWithdrawals', this.allWithdrawals)
          this.getAssetsInfoFlg = true;
      }, 1500);
    });
  }
  getusername(uid) {
    this.bitshareService.getUsername(uid).then((userAccountName: any) => {
      return userAccountName;
    });
  }
  formatAmountwithPrec(amount, assetId) {
    if (assetId && amount) {
      //console.log('assetId', assetId)
      if (this.getAssetsInfoFlg === true) {
        var thisAssProp = this.bitshareAssetInfo[assetId];
        //console.log('thisAssProp', thisAssProp);
        const currBal: string = amount.toString();
        let curBalnFor: any;

        if (assetId != '1.3.0') {
          if (typeof (thisAssProp['precision']) !== undefined) {
            if (currBal.length > thisAssProp.precision) {
              const f: string = currBal.substring(0, (currBal.length - thisAssProp.precision));
              const s: string = currBal.substring((currBal.length - thisAssProp.precision), currBal.length);
              curBalnFor = f + '.' + s;
            } else {
              const diff = thisAssProp.precision - currBal.length;
              let f = '0.';
              for (var i = 1; i <= diff; i++) {
                f = f + '0';
              }
              curBalnFor = f + currBal;
            }
          }
        } else { //5
          const f: string = currBal.substring(0, (currBal.length - 5));
          const s: string = currBal.substring((currBal.length - 5), currBal.length);
          curBalnFor = f + '.' + s;
        }

        return curBalnFor;
      }
    } else {
      return amount;
    }
  }
  
  getAllBSAssets() {
    this.bitshareService.getGatewayCoins().subscribe((bsAssets: any) => {
      bsAssets.forEach((bsitm, bsind) => {
        this.allBSAssets.push(this.BASE_ASSET + '.' + bsitm);
      });
      //console.log('this.allBSAssets', this.allBSAssets);
      Apis.instance().db_api().exec( "lookup_asset_symbols", [this.allBSAssets]).then((assetsInfoBS:any) => {
        
        //check bsbalance length 0 and add it
        if(this.BSBalance && this.BSBalance.length) {
          
          assetsInfoBS.forEach((asst, assi) => {
            let hasBal = false;
            this.bitshareAssetInfo[asst.id] = asst;                  
            this.BSBalance.forEach((bsast, bsind) => {
              if(asst.symbol == bsast.symbol) {
                hasBal = true;
              }
            });
            if (hasBal == false) {
              const bslen = this.BSBalance.length;
              this.BSBalance[bslen] = assetsInfoBS[assi];
              this.BSBalance[bslen]['balance'] = 0
            }
          });
        } else {
          assetsInfoBS.forEach((asst, assi) => {
            this.bitshareAssetInfo[asst.id] = asst;
            assetsInfoBS[assi]['balance'] = 0;
          });
          this.BSBalance = assetsInfoBS;
        }
        
      });
    });
  }
  getAllFSTransactions() {
    this.bitshareService.getBSTransId().subscribe((transDocId: any) => {
      console.log('transDocId', transDocId);
      if(transDocId.length) {
        const docId = transDocId[0].udid;
        this.bitshareService.getAllBSTransactions(docId).subscribe((transactions: any) => {
          console.log('-+-transactions-+-', transactions);
          this.allFSTransactions = transactions;
          this.toGetAddress = true;
          
        });
      }
    });
  }
  
  getATransaction(id) {
    //console.log('To get row for the id is ', id);
    //if(this.toGetAddress == true) {
        let foundRes: boolean = false;
        this.allFSTransactions.forEach((trans, tid) => {
          if(id == trans.id && foundRes == false) {
            foundRes = true;
            console.log(id + '==' + trans.id + '=>' + trans.memo_decrypted);
            return "-" + trans.memo_decrypted;
          }
        });
    //}
    
  }
  
  updateStatusPop(confStatusUpdate, tid) {
    this.transId = tid;
    this.wdUpdateRes = '';
    this.modalReference = this.modalService.open(confStatusUpdate, {backdrop: 'static'});
  }
  
  updateStatus(tid) {
    console.log('Id to update', tid);
    this.wdUpdateRes = 'processing';
    this.bitshareService.getBSTransId().subscribe((transDocId: any) => {
      console.log('transDocId', transDocId);
      if(transDocId.length) {
        const docId = transDocId[0].udid;
        this.bitshareService.updateWithdrawStatus(tid, docId).then(updResp => {
          console.log('updResp', updResp);
          if(updResp.message == 'success') {
             this.wdUpdateRes = 'success';
          } else {
            this.wdUpdateRes = 'error';
          }
          setTimeout(() => {
             this.modalReference.close();
             this.redirectTo('deposits-withdrawals');
          }, 2000);
        });
      }
    });
    
  }
  
  redirectTo(uri:string){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
  }

  closePop(popclose) {
    this.modalReference.close();
  }
}
