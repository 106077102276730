import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addBit'
})
export class AddBitPipe implements PipeTransform {

  transform(value: string): string {
    if (value) {
      const bitPair = (value === 'CNY') ? ('bit' + value) : (value === 'USD') ? ('bit' + value) : (value === 'EUR') ? ('bit' + value) : value;
      return bitPair;
    } else {
      return value;
    }
  }
}
