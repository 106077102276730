import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { map, shareReplay } from 'rxjs/operators';
import { Observable, combineLatest, Subscription, Subject, timer } from 'rxjs';
import { Apis, Manager, ChainConfig } from 'bitsharesjs-ws';
var { Login, ChainStore, FetchChain, TransactionHelper, Aes, TransactionBuilder, PrivateKey, key } = require("bitsharesjs");
import firebase from 'firebase/app';


@Injectable({
  providedIn: 'root'
})
export class BitshareService {
  public httpOptions: any;
  public apiUrl;
  public bsUrl: string;
  public bsNodeURLs: Array<any> = [];;
  public domainsAPIURL: string;
  public hostUrl;
  public allBSTransactions: any;
  
  constructor(
    private http: HttpClient,
    private afs: AngularFirestore,
    private router: Router
  ) {
    
    const BASEURL_PROP = this.router;
    let BASEURL = BASEURL_PROP['location']['_platformLocation']['location']['origin'];
    //console.log('BASEURL', BASEURL);
    if(BASEURL_PROP['location']['_platformLocation']['location']['hostname'] == 'localhost') {
      BASEURL = 'https://api.testnet.blockbasis.com';
    } else {
      BASEURL = BASEURL.replace(/admin/g, 'api');
    }
    //const BASEURL = 'https://api.testnet.blockbasis.com';
    //console.log('BASEURL', BASEURL);
    this.domainsAPIURL = BASEURL;
    this.getSetURL();

  }
  
  getSetURL() {
    return new Promise<any>((resolve, reject) => {
      this.getClientConfig().subscribe(configData => {
        console.log('configData', configData);
        this.apiUrl = 'https://' + configData['API_HOSTNAME'];
        this.hostUrl = 'https://' + configData['PRO_HOSTNAME'];
        if(configData['BITSHARES_NODES'] && configData['BITSHARES_NODES'].length > 0) {    
          this.bsNodeURLs = [];
          //this.bsUrl = configData['BITSHARES_NODES'][0];
          //this.bsUrl = "wss://eu.nodes.bitshares.ws";
          this.bsUrl = "wss://testnet.blockbasis.com"; //wss://node.testnet.bitshares.eu
          for(var i = 0; i<= configData['BITSHARES_NODES'].length-1; i++){
            this.bsNodeURLs.push(configData['BITSHARES_NODES'][i]);
          }
          /*this.bsNodeURLs.push("wss://kimziv.com/ws");
          this.bsNodeURLs.push("wss://eu.nodes.bitshares.ws");
          this.bsNodeURLs.push("wss://api.weaccount.cn");
          this.bsNodeURLs.push("wss://api.bts.mobi/ws");
          this.bsNodeURLs.push("wss://api.dex.trading/");*/
          //this.bsNodeURLs = "wss://api.bts.mobi/ws";
          //console.log('this.bsNodeURLs-'+this.bsNodeURLs+'-');

          resolve(configData);
        }
      }, err => {
        console.log('err', err);
      });
    });
  }
  
  getClientConfig() {
    console.log('this.domainsAPIURL', this.domainsAPIURL);
    return this.http.get(this.domainsAPIURL + '/client/config');
  }
  
  async bsConnect() {

    if(!this.bsUrl) {
       const temp = await this.getSetURL();
       console.log('temp', temp)
       //console.log('this.bsUrl', this.bsUrl);
    }
    
    return new Promise<any>((resolve, reject) => {
        if(this.bsUrl) {
            const man = new Manager({url: this.bsUrl, urls: this.bsNodeURLs});
            const that = this;
            console.log('that.bsUrl', that.bsUrl);
            console.log('that.bsNodeURLs', that.bsNodeURLs);
            man.checkConnections().then(function(res) {
                console.log('Latency Check Response ', res);
                const mapped = Object.keys(res).map(key => ({url: key, ms: res[key]}));
                console.log('mapped', mapped);
                console.log('mapped.length', mapped.length);
                
                if(mapped.length) {
                    mapped.sort(function (a, b) {
                      return a.ms - b.ms;
                    });
                    that.bsUrl = mapped['0']['url'];
                    
                    that.bsNodeURLs = [];
                    for(var i = 1; i<= mapped.length-1; i++){
                        that.bsNodeURLs.push(mapped[i]['url']);
                    }
                    

                    const lowlatser = new Manager({url: that.bsUrl, urls: that.bsNodeURLs});
                    lowlatser.connect().then(function(lowlatserres) {
                        const con = Apis.instance().init_promise;
                        ChainStore.init(false).then(() => {
                            //console.log('con', con);
                            if (Apis.instance().chain_id == '67afd6cb481e7ede5634f6c554489c1a34f84f44d6990e5a10e6fcdc981e67c0') {
                              Apis.instance().db_api().exec( "get_chain_id", []).then((chainID:any) => {
                                console.log('development chainID', chainID);
                                Apis.instance().db_api().exec( "get_config", [chainID]).then((get_config:any) => {
                                    console.log("get_config bsconnect ", get_config);
                                    ChainConfig.setPrefix(get_config['GRAPHENE_ADDRESS_PREFIX']);
                                    
                                });
                              });
                            }
                            resolve(lowlatserres);
                        });  
                    }).catch(function(err) {
                       resolve({'WSSConnection': true});
                    });
                } else {
                  resolve({'WSSConnection': true});
                }
                //man.setCloseCb(null);
            });
            
        }
    });
    
  }
  
  
  getBBTransAPI(start, stop) {
    return new Promise<any>((resolve, reject) => {
      if(Apis.instance().chain_id) {
	    /*
		Promise.all([
			FetchChain("getAccount", username)
		]).then((accRes) => {
			let [username] = accRes;
			   
			if (username) { //username is valid
				Apis.instance().db_api().exec( "get_account_history", []).then((chainID:any) => {
				});
			}
		});
		*/
		Apis.instance().db_api().exec( "get_account_history", ['block-basis', start, 100, stop]).then((accBBHis:any) => {
		  console.log('accBBHis', accBBHis);
		  resolve(accBBHis);
		});
	  } else {
	    resolve({'Missing API chain ID': true});
	  }
	});
  }
  
  
  getGatewayCoins() {
    if (this.apiUrl) {
        return this.http.get(this.apiUrl + '/gateway/active-wallets');
    } else {
       this.getSetURL().then(configData => {
         return this.http.get(this.apiUrl + '/gateway/active-wallets');
       });
    }
  }
  
  getBaseAsset () {
    return new Promise<any>((resolve, reject) => {
      this.getGateway().subscribe((coreAssetBS: any) => {
        console.log('coreAssetBS', coreAssetBS);

        resolve(coreAssetBS['BASE_ASSET']);
      });
    });
  }
  
  getGateway() {
    if (this.apiUrl) {
        return this.http.get(this.apiUrl + '/gateway');
    } else {
       this.getSetURL().then(configData => {
         return this.http.get(this.apiUrl + '/gateway');
       });
    }
  }
  
  getRecentHistory(accountName) {
    return new Promise<any>((resolve, reject) => {
      this.getBSTransId().subscribe((transDocId: any) => {
        console.log('transDocId', transDocId);
        if(transDocId.length) {
          const docId = transDocId[0].udid;
          let accountHistory:any = [];
  this.getAllBSTransactions(docId).subscribe((transactions: any) => {
    console.log('-+-transactions', transactions);
    this.allBSTransactions = transactions;
  
          Promise.all([
            FetchChain("getAccount", accountName)
          ]).then((accInfo)=> {
            let [username] = accInfo;
            var account_id = username.get("id");
            console.log('account_id', account_id)
            Promise.all([
              FetchChain("fetchRecentHistory", account_id)
            ]).then((accHis)=> {
              let [userAccHis] = accHis;
              var current_history = userAccHis.get("history");
      console.log('current_history', current_history);
              if (current_history.size) {
                if (current_history._root) {
                  var hisRoot = current_history._root;
        console.log('hisRoot', hisRoot);
                  if (hisRoot.array.length) {
                      for (var i=0; i < hisRoot.array.length; i++) {
                          hisRoot.array[i].array.forEach((assetIcon, ind) => {
                if(assetIcon._root) {
                  const transacData = assetIcon._root.entries
                let transType, issuerName, toName, fromId, toId, memoMessage, fromAlias, fromName;
                let showThis:boolean = false;
                if(transacData[1][1]['_tail']['array'][1]['_root']['entries']) { 
                    transType = 'Entry';
                    toName = issuerName = fromId = toId = fromName = '' ;
                    if (transacData[1][1]['_tail']['array'][1]['_root']['entries'][2][0] == 'to') {
                    toId = transacData[1][1]['_tail']['array'][1]['_root']['entries'][2][1];
                    
                    }
                    if (transacData[1][1]['_tail']['array'][1]['_root']['entries'][1][0] == 'issuer') {
                    toId = transacData[1][1]['_tail']['array'][1]['_root']['entries'][1][1];
                    }
                    if(transacData[1][1]['_tail']['array'][1]['_root']['entries'][1][0] == 'from') {	  
                    fromId = transacData[1][1]['_tail']['array'][1]['_root']['entries'][1][1];
                  
                    }
                    if(transacData[1][1]['_tail']['array'][1]['_root']['entries'][3][0] == 'issue_to_account') {	  
                    fromId = transacData[1][1]['_tail']['array'][1]['_root']['entries'][3][1];
                    }
                    fromName = fromId;
                    if(fromName != '') {
                      this.getUsername(fromId).then(fname => {
                          fromName = fname
                          
                      });
                    }
                }
                if(transacData[1][1]['_tail']['array'][1]['_root']['nodes']) {
                    transType = 'Nodes';
                    toName = issuerName = fromId = toId = fromName = '';
                    if (transacData[1][1]['_tail']['array'][1]['_root']['nodes'][2][0] == 'to') {
                    toId = transacData[1][1]['_tail']['array'][1]['_root']['nodes'][2][1];
                    }
                    if (transacData[1][1]['_tail']['array'][1]['_root']['nodes'][1][0] == 'from'  || account_id == transacData[1][1]['_tail']['array'][1]['_root']['nodes'][2][1]) {
                    fromId = transacData[1][1]['_tail']['array'][1]['_root']['nodes'][1][1];
                    
                    }
                    fromName = fromId;
                    if(fromName != '') {
                      this.getUsername(fromId).then(fname => {
                          fromName = fname
                          console.log('fname', fname);
                      });
                    }
                }
                if(transType == 'Entry' && transacData[1][1]['_tail']['array'][1]['_root']['entries'][1][0] == 'from') {
                    showThis = true;
                  if(transacData[1][1]['_tail']['array'][1]['_root']['entries'][2][0] == 'to') {
                      if(transacData[1][1]['_tail']['array'][1]['_root']['entries'][1][1] != transacData[1][1]['_tail']['array'][1]['_root']['entries'][2][1]) {
                      //showThis = false;
                    }
                  }
                }
                if(showThis === true) {
                        let foundRes: boolean = false;
                    const id = transacData[0][1];
                        this.allBSTransactions.forEach((trans, tid) => {
                      if(id == trans.id && foundRes == false) {
                      foundRes = true;
                      //console.log(id + '==' + trans.id + '=>' + trans.memo_decrypted);
                      memoMessage = trans.memo_decrypted;
                      fromAlias = trans.from_alias
                      }
                    });
                    Apis.instance().db_api().exec( "get_block", [transacData[3][1]]).then((ordersInfo:any) => {
                      accountHistory.push({
                      'Id': transacData[0][1],
                      'Data': (transType == 'Entry') ? transacData[1][1]['_tail']['array'][1]['_root']['entries'] : transacData[1][1]['_tail']['array'][1]['_root']['nodes'],
                      'Type': transType,
                      'Block': transacData[3][1],
                      'TransactionCount' : transacData[4][1],
                      'Timestamp': ordersInfo.timestamp,
                      'issuerName': issuerName,
                      'toName' : toName,
                      'userId' : account_id,
                      'fromId' : fromId,
                      'toId' : toId,
                      'memoMessage' : memoMessage,
                      'fromAlias' : fromAlias,
                      'fromName' : fromName
                      });
                    });
                }
              }
                          });
                      }
                  }
                  
                }
                if (current_history._tail) {
                  var hisTail = current_history._tail;
        console.log('hisTail', hisTail);
        if(hisTail.array.length) {
            for (var i=0; i < hisTail.array.length; i++) {
              const assetIcon = hisTail.array[i];
            //console.log('assetIcon', assetIcon)
                          //hisTail.array[i].forEach((assetIcon, ind) => {
                if(assetIcon._root) {
              
                const transTaData = assetIcon._root.entries;
                let transTaType, transTaInfo, issuerName, toName, toId, fromId, memoMessage, fromAlias, fromName;
                let showThis:boolean = true;
                
                if(transTaData[1][1]['_tail']['array'][1]['_root']['entries']) {
                  transTaType = 'Entry';
                  toName = issuerName = '';
                  toId = transTaData[1][1]['_tail']['array'][1]['_root']['entries'][1][1];
                  fromId = transTaData[1][1]['_tail']['array'][1]['_root']['entries'][2][1];
                  
                }
                if(transTaData[1][1]['_tail']['array'][1]['_root']['nodes']) {
                  transTaType = 'Nodes';
                  toName = issuerName = '';
                  toId = transTaData[1][1]['_tail']['array'][1]['_root']['nodes'][2][1];
                  fromId = transTaData[1][1]['_tail']['array'][1]['_root']['nodes'][1][1];
                   
                }
                fromName = fromId;
                if(fromName != '') {
                  this.getUsername(fromId).then(fname => {
                  fromName = fname;
                  });
                  }
                if(showThis === true) {
                    let foundRes: boolean = false;
                  const id = transTaData[0][1];
                  this.allBSTransactions.forEach((trans, tid) => {
                    if(id == trans.id && foundRes == false) {
                    foundRes = true;
                    //console.log(id + '==' + trans.id + '=>' + trans.memo_decrypted);
                    memoMessage = trans.memo_decrypted;
                    fromAlias = trans.from_alias;
                    }
                  });
                  Apis.instance().db_api().exec( "get_block", [transTaData[3][1]]).then((ordersInfo:any) => {
                    accountHistory.push({
                      'Id': transTaData[0][1],
                      'Data': (transTaType == 'Entry') ? transTaData[1][1]['_tail']['array'][1]['_root']['entries'] : transTaData[1][1]['_tail']['array'][1]['_root']['nodes'],
                      'Type': transTaType,
                      'Block': transTaData[3][1],
                      'TransactionCount' : transTaData[4][1],
                      'Timestamp': ordersInfo.timestamp,
                      'issuerName': issuerName,
                      'toName' : toName,
                      'userId' : account_id,
                      'toId' : toId,
                      'fromId' : fromId,
                      'memoMessage' : memoMessage,
                      'fromAlias' : fromAlias,
                      'fromName' : fromName
                    });
                  });
                }
                }
            //});
          }
        }
                  
                }
              }
            });
            resolve(accountHistory);
          });
      }); 
        }
      });
        
    });
  }
  
  getBSTransId() {
    const gatewayDocId = this.afs.collection(`Gateway`, ref => ref.where('accountName', '==', 'block-basis')).snapshotChanges().pipe(
      map(changes => changes.map(a => {
        const data = a.payload.doc.data() as any;
        data.udid = a.payload.doc.id;
        return data;
      }))
    );
    return gatewayDocId;
  }

  getAllBSTransactions(did) {
    const latestTrans = this.afs.collection('Gateway').doc(did).collection(`transactions`, ref => ref.where('status', 'in', ['withdraw_request', 'withdraw_completed'])).valueChanges();
    return latestTrans;
  }
  
  getUsername(userId) {
    //console.log('userId to fetcch for from or to', userId);
    return new Promise<any>((resolve, reject) => {
        let userinfo = userId;
        Promise.all([
            FetchChain("getAccount", userId) //219 236 223
        ]).then((res)=> { 
            let [userinfo] = res;
            if(userinfo) {
              const username = userinfo.get("name");
              resolve(username + ' - ' + userId);
            } else {
              resolve({'response': 'unknownUser'});
            }
        }).catch(error => { 
            console.log('error', error);
            Apis.instance().init_promise;
            this.getUsername(userId);
            reject(error);
        });
    });
  }
  
  updateWithdrawStatus(transId, docId) {
    return new Promise<any>((resolve, reject) => {
      
	  const storageRef = this.afs.collection('Gateway').doc(docId).collection('transactions').doc(transId)
      //withdraw_request withdraw_completed
      var updateData = {
        status: 'withdraw_completed',
      }
      storageRef.update(updateData).then(res => {
        resolve({'message':'success'});
      }, err => {
        reject(err);
        console.log('update server ERR', err);
      });
    });
  }
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
