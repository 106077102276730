import { Component, OnInit } from '@angular/core';
import firebase from 'firebase/app';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask  } from '@angular/fire/storage';

import { DialogOverviewExampleDialog } from '../dialog-overview-example/dialog-overview-example';
import { ConfigService } from '../services/config.service';
import { UsersService } from '../services/users.service';
import { GlobalvariableProvider } from '../providers/globalprovider';
import { ReplaceOpenPipe } from '../pipes/replace-open.pipe';

@Component({
  selector: 'app-supportedassets',
  templateUrl: './supportedassets.component.html',
  styleUrls: ['./supportedassets.component.css']
})
export class SupportedassetsComponent implements OnInit {
  coinName: string;
  accessToken: string;
  supportedAssets: any;
  assetManagement: any;
  modalReference: any;
  asset: string;
  user: any;
  iconPath: string;
  supportedAssetIcon: any = [];
  loadedAssetIcon: string;
  selectedAssets: any;

  assetOptionChoice = {
    transfer_enabled: false,
    exchange_enabled: false,
    withdraw_enabled: false,
    deposit_enabled: false,
    trading_pairs: null
  };
  constructor(
    public configService: ConfigService,
    public userService: UsersService,
    private modalService: NgbModal,
    private router: Router,
    public global: GlobalvariableProvider,
    public dialog: MatDialog,
    private afStorage: AngularFireStorage,
  ) { }

  ngOnInit() {
    this.userService.getCurrentUser().then(res => {
      if (res) {
        this.user = res;
        firebase.auth().currentUser.getIdToken().then(authToken => {
          this.accessToken = authToken;
          // this.getSupportedAssets(this.accessToken);
          this.loadedAssetIcon = 'false';
          // this.getAssetManagement();
        });
      }
    });
    if (!this.global.loggedin) {
      this.router.navigate(['/']);
    }
  }

  openDialog(coinName): void {
    this.coinName = new ReplaceOpenPipe().transform(coinName);
    this.coinName = this.coinName.toLowerCase();
    this.iconPath = 'NULL';
    this.afStorage.ref('/assets/' + this.coinName + '.png').getDownloadURL().subscribe(pngURL => {
      this.iconPath = pngURL;
      this.showDialog();
    }, err => {
      this.iconPath = 'NULL';
      console.log('not found for png', err);
      this.afStorage.ref('/assets/' + this.coinName + '.jpg').getDownloadURL().subscribe(jpgURL => {
        this.iconPath = jpgURL;
        this.showDialog();
      }, error => {
        this.iconPath = 'NULL';
        console.log('not found for jpg', error);
        this.showDialog();
      });
    });
  }
  showDialog() {

    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '400px',
      data: {coinName: this.coinName, iconPath: this.iconPath}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }
  
  // getAssetManagement() {
  //   this.configService.getEnabledAssetsCoins().subscribe(assetManagement => {
  //     this.assetManagement = assetManagement;
  //   }, err => {
  //     console.log('err', err);
  //   });
  // }

  // getSupportedAssets(accessToken) {
  //   this.configService.getSupportedAssets(accessToken).subscribe(supportedAssets => {
  //     this.supportedAssets = supportedAssets['assets_supported'];
  //     const assetLen = this.supportedAssets.length;
  //     this.supportedAssets.forEach((assets, aind) => {
  //       this.loadAssetIcon(assets);
  //       if (aind == (assetLen -1)) {
  //           this.loadedAssetIcon = 'true';
  //       }
  //     })
  
  //   }, err => {
  //     console.log('err', err);
  //   });
  // }

  useAsset(asset, assetOptions) {
    this.asset = asset;
    const enabledAssets = this.assetManagement.assets;
    const selectedAssets = enabledAssets.filter(enabledAsset => enabledAsset.asset === asset);
    if (selectedAssets.length > 0) {
      this.selectedAssets = selectedAssets[0];
      this.assetOptionChoice.transfer_enabled = selectedAssets[0].options.transfer_enabled;
      this.assetOptionChoice.withdraw_enabled = selectedAssets[0].options.withdraw_enabled;
      this.assetOptionChoice.deposit_enabled = selectedAssets[0].options.deposit_enabled;
      this.assetOptionChoice.exchange_enabled = selectedAssets[0].options.exchange_enabled;
    }
    this.modalReference = this.modalService.open(assetOptions, {backdrop: 'static', size: 'sm'});
  }

  enableAssets(asset, assetOptionChoice) {
    const assetData = {
      asset: asset,
      options: {
        'enabled': true,
        'transfer_enabled': assetOptionChoice.transfer_enabled,
        'exchange_enabled': assetOptionChoice.exchange_enabled,
        'withdraw_enabled': assetOptionChoice.withdraw_enabled,
        'deposit_enabled': assetOptionChoice.deposit_enabled,
        'trading_pairs': null
      }
    };
    if (this.assetManagement && this.assetManagement.assets.length > 0) {
      let i = 0;
      let assetExist = false;
      for (const assetMgt of this.assetManagement.assets) {
        if (assetMgt.asset === asset) {
          assetExist = true;
          let tradingPairs = null;
          if (assetMgt.options.trading_pairs !== null && assetMgt.options.trading_pairs.length > 0) {
            tradingPairs = assetMgt.options.trading_pairs;
          }
          this.assetManagement.assets.splice(i, 1);
          if (assetMgt.options.trading_pairs !== null && assetMgt.options.trading_pairs.length > 0) {
            assetData.options.trading_pairs = tradingPairs;
          }
          this.assetManagement.assets.push(assetData);
          this.saveAssetToFirebase(this.assetManagement);
        } else {
          assetExist = false;
        }
        i++;
      }
      if (!assetExist) {
        if (this.assetManagement && this.assetManagement.assets.length > 0) {
          this.assetManagement.assets.push(assetData);
          this.saveAssetToFirebase(this.assetManagement);
        }
      }
    } else {
       if (this.assetManagement && this.assetManagement.assets.length > 0) {
        this.assetManagement.assets.push(assetData);
        this.saveAssetToFirebase(this.assetManagement);
      }
    }
  }

  saveAssetToFirebase(assetManagement) {
    this.configService.saveAssetManagement(assetManagement).then(savedAsset => {
      this.modalReference.close();
      this.router.navigateByUrl('blank').then(() => {
        this.router.navigateByUrl('supported-assets');
      });
    }, err => {
      console.log('err', err);
    });
  }

  closePop(popclose) {
    this.modalReference.close();
  }
  loadAssetIcon(coinName) {
    let imageName = new ReplaceOpenPipe().transform(coinName);
    imageName = imageName.toLowerCase();
    
    const that = this;
    
    this.afStorage.ref('assets/' + imageName + ".png").getDownloadURL().subscribe(pngURL => {
      that.supportedAssetIcon.push({'name': imageName, 'path': pngURL});
    }, err => {

      this.afStorage.ref('assets/' + imageName + ".jpg").getDownloadURL().subscribe(jpgURL => {
        that.supportedAssetIcon.push({'name': imageName, 'path': jpgURL});
      }, err => {
        that.supportedAssetIcon.push({'name': imageName, 'path': 'https://img.icons8.com/material-outlined/50/000000/no-image.png'});
      });
    });
    

  }
  
  getAssetIcon(thisCoin) {
    let coinName = new ReplaceOpenPipe().transform(thisCoin);
    coinName = coinName.toLowerCase();
    for (let iconData of this.supportedAssetIcon) {
      if (iconData.name === coinName) {
          return iconData.path;
      }
    }
  }
}
