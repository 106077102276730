import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import firebase from 'firebase/app';
import { UsersService } from '../services/users.service';
import { ConfigService } from '../services/config.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-burn-issue',
  templateUrl: './burn-issue.component.html',
  styleUrls: ['./burn-issue.component.scss']
})
export class BurnIssueComponent implements OnInit {
  
  public currOperation: string;
  public refBlockNum: string;
  public issueResponse: string;
  public burnResponse: string = '';
  public burnForm: FormGroup;
  public issueForm: FormGroup;
  public BIApi: string;
  public accessToken: string;
  public issue = {amount: '', symbol: '', to: ''};
  public loading = false;
  public allBSAssets: any = [];
  public BASE_ASSET: string;
  public assetsSymbol: any[] = [];
  
  constructor(private router: Router, private http: HttpClient, public userService: UsersService, public configService: ConfigService, private spinner: NgxSpinnerService) { 
      
    const BASEURL_PROP = this.router;
    let BASEURL = BASEURL_PROP['location']['_platformLocation']['location']['origin'];
    console.log('BASEURL', BASEURL);
    if(BASEURL_PROP['location']['_platformLocation']['location']['hostname'] == 'localhost') {
      BASEURL = 'https://api.testnet.blockbasis.com';
    } else {
      BASEURL = BASEURL.replace(/admin/g, 'api');
    }
    this.BIApi = BASEURL;
    this.createBurnForm();
      this.createIssueForm();
	  console.log('configService param', this.configService.domainsAPIURL);
	  /*this.configService.getBaseAsset().then(BASE_ASSET => {
	    console.log('BASE_ASSET', BASE_ASSET);
        localStorage.setItem('BASE_ASSET', BASE_ASSET);
        this.BASE_ASSET = BASE_ASSET;
        
      });*/
	  
  }
  
  
  
  validationMessages = {
    amount: [
      { type: 'required', message: 'Amount is required.' }
    ],
    symbol: [
      { type: 'required', message: 'Symbol is required.' },
    ],
    to: [
      { type: 'required', message: 'To is required.' },
    ],
  };

  createBurnForm() {
    this.burnForm = new FormGroup({
      amount: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      symbol: new FormControl('', Validators.compose([
        Validators.required,
      ]))
    });
  }
  
  createIssueForm() {
    this.issueForm = new FormGroup({
      amount: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      symbol: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      to: new FormControl('', Validators.compose([
        Validators.required,
      ]))
    });
  }
  
  ngOnInit() {
      //this.getAllBSAssets();
	  
	  this.spinner.show();
	  /*
	  this.assetsSymbol.push({"id":"BTC", "symbol":"BLOCK.BTC"});
	  this.assetsSymbol.push({"id":"ETH", "symbol":"BLOCK.ETH"});
	  this.assetsSymbol.push({"id":"LTC", "symbol":"BLOCK.LTC"});
	  */
	  console.log('this.assetsSymbol', this.assetsSymbol);
      var currURL = this.router.url;
      this.currOperation = currURL.replace("/", "");
      console.log(this.currOperation);
      
      /*firebase.auth().currentUser.getIdToken().then(authToken => {
          this.accessToken = authToken;
      });*/
      this.userService.getCurrentUser().then(res => {
        if (res) {
          firebase.auth().currentUser.getIdToken().then(authToken => {
            console.log('authToken', authToken);
            this.accessToken = authToken;
          });
		  setTimeout(() => {
		    this.configService.getActiveWallets().subscribe(wallets => {
	          console.log('wallets', wallets);
		      /*wallets.forEach((wsym, wind) => {
		        this.assetsSymbol.push({"id":wsym, "symbol":this.configService.prefixAsset+"."+wsym});
		      });*/
		      Object.entries(wallets).forEach(
		        ([key, value]) => {
				  console.log(key, value);
				  this.assetsSymbol.push({"id":value, "symbol":this.configService.prefixAsset+"."+value});
			    }
		      );
			  this.spinner.hide();
		      //console.log('this.configService.prefixAsset', this.configService.prefixAsset);
	        });
	  
	      }, 2000);
        }
      });
      if(this.currOperation == 'burn') {
        /*
        {
            "amount": "0.011",
            "symbol": "BLOCK.BTC"
        }
        */
      } else { //issue
        /*
        {
            "amount": "0.011",
            "symbol": "BLOCK.BTC",
            "to": "fuck-fuck"
        }
        */
      }
  }
  
  coinIssue(issue) {
      console.log('issue  ', issue);
      console.log('issue val ', issue.value);
	  this.loading = true;
	  this.issueResponse = 'processing';
      //const headers = new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.accessToken });
      /*
      var header = {
          headers: new HttpHeaders()
            .set('Authorization',  `Bearer `+ this.accessToken)
            .set('Content-Type',  'application/json')
        }
      */
      /*
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer `+this.accessToken
        });
        const params = {"amount":"0.011", "symbol":"BLOCK.BTC", "to":"fuck-fuck"};
        //, {"amount":"0.011", "symbol":"BLOCK.BTC", "to":"fuck-fuck"}
        this.http.post(this.BIApi + 'gateway/issue', params, {headers}).subscribe((createResponse:any) => {
             console.log('createResponse', createResponse)
        });
      */
      //return this.http.get();
      
      
            
      //const params = {"amount":"0.011", "symbol":"BLOCK.BTC", "to":"fuck-fuck"};
      //console.log('this issue', this.issue);
      //console.log('-----------------------');
      //console.log('issue', issue);
      
	  //validate to username account
	  /*
	  const APIurl = this.BIApi + '/tasks/lookup/' +issue.value.to;
      
      const headersParam = new HttpHeaders({Authorization: 'Bearer ' + this.accessToken });
	  const requestOptions = { headers: headersParam};
      
      this.http.get(APIurl, requestOptions).subscribe((userValiResp:any) => {
        console.log('userValiResp', userValiResp);
      });
	  */
	  const params = {"amount":issue.value.amount, "symbol":issue.value.symbol, "to":issue.value.to};
      console.log('params', params);
	  const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer `+this.accessToken
      });
      this.http.post(this.BIApi + '/gateway/issue', params, {headers}).subscribe((issueResponse:any) => {
        console.log('issueResponse', issueResponse);
		this.loading = false;
        this.issueForm.reset();
		this.issueForm.controls['amount'].setErrors(null);
		this.issueForm.controls['symbol'].setErrors(null);
		this.issueForm.controls['to'].setErrors(null);
		this.issueForm.get('amount').setValue(null);
		this.issueForm.get('symbol').setValue(null);
		this.issueForm.get('to').setValue(null);
        if(issueResponse.ref_block_num) {
          this.issueResponse = 'success'; 
          this.refBlockNum = issueResponse.ref_block_num;
        } else {
          this.issueResponse = 'error';
        }
      }, err => {
		console.log(err);
		//console.log(Error);
		console.log(err.error.status);
		if(err.error.status == 'Account does not exists') {
		  this.issueResponse = 'account';
		}
	 }, () => {
	   console.log('DONE ALL');
	 }
	 );
      
  }
  
  coinBurn(burn) {
	this.burnResponse = 'processing';
	this.loading = true;
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer `+this.accessToken
    });
    const params = {"amount":burn.value.amount, "symbol":burn.value.symbol};
    this.http.post(this.BIApi + '/gateway/burn', params, {headers}).subscribe((burnResponse:any) => {
        console.log('burnResponse', burnResponse);
		this.loading = false;
        //this.burnForm.reset();
		this.burnForm.controls['amount'].setErrors(null);
		this.burnForm.controls['symbol'].setErrors(null);
		this.burnForm.get('amount').setValue(null);
		this.burnForm.get('symbol').setValue(null);
        if(burnResponse.ref_block_num) {
          this.burnResponse = 'success';
          this.refBlockNum = burnResponse.ref_block_num;
        } else {
          this.burnResponse = 'error';
        }
      }, err => {
		console.log(err);
		console.log(Error);
		console.log(err.status);
		if(err.error.status) {
		  this.burnResponse = 'account';
		}
	 }, () => {
	   console.log('DONE ALL');
	 }
	);
  }
  
  handleError(error: Response) {
    console.log(error.status);
    if (error.status == 500) {      
      
    } else {
      return Observable.throw(error);
    }
  }
  get bf() { return this.burnForm.controls; }
  get isf() { return this.issueForm.controls; }
  
}
