import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../services/auth.service';
import { GlobalvariableProvider } from '../providers/globalprovider';
import { UsersService } from '../services/users.service';
import { firebase } from 'firebase/firebase';
import { FirebaseApp } from '@angular/fire';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('linkMultipleAccount') linkMultipleAccount: ElementRef<HTMLElement>;
  login = {email: '', password: ''};
  errorMessage: any;
  private readonly notifier: NotifierService;
  /*
  private supportedPopupSignInMethods = [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.GithubAuthProvider.PROVIDER_ID,
    firebase.auth.TwitterAuthProvider.PROVIDER_ID
  ];
  */
  public popupErrorMessage;
  public loading = false;
  public credentials: any;
  public linkedProvider: any;
  public loginwithemailpass: boolean = false;
  
  constructor(
    private authService: AuthService,
    private router: Router,
    public afAuth: AngularFireAuth,
    public global: GlobalvariableProvider,
    private spinner: NgxSpinnerService,
    notifierService: NotifierService,
    private userService: UsersService
  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.authService.isUserLoggedIn().subscribe(user => {
      if (user) {
        this.afAuth.currentUser.then(authToken => {
          this.router.navigate(['/dashboard']);
        });
      }
    });
  }

  tryLogin(login) {
    this.spinner.show();
    const credentials = {email: login.email, password: login.password};
    this.authService.doLogin(credentials).then(success => {
      this.userService.getCurrentUser().then(user => {
        if (user.roles.admin) {
          this.spinner.hide();
          this.global.loggedin = true;
          this.router.navigate(['/dashboard']);
        } else {
          this.spinner.hide();
          this.authService.doLogout().then(logout => {
            this.errorMessage = 'You don\'t have access to admin panel';
            this.notifier.notify( 'error', this.errorMessage);
            this.global.loggedin = false;
          }).catch(error => {
            console.log(error);
          });
        }
      });
    }).catch(error => {
    this.spinner.hide();
      console.log(error);
      if (error.code === 'auth/wrong-password') {
        this.errorMessage = 'Invalid username or password ';
      } else if (error.code === 'auth/invalid-email') {
        this.errorMessage = 'Please enter Email and Password';
      } else {
        this.errorMessage = 'Something went wrong, please try again';
      }
      this.notifier.notify( 'error', this.errorMessage);
    });
  }
  
  loginWithGoogle() {
    this.loading = true;
    this.authService.doGoogleLogin().then(res => {
	  console.log('Google login response', res);
      const uid = res.user.uid;
      const additionalUserInfo = res.additionalUserInfo.profile;
      this.checkUserAfterLogin('google', uid, additionalUserInfo);

    }, err => {
      this.loading = false;
      console.log('err', err);
      this.errorMessage = 'Something went wrong, please try again or sign up below';
    });
  }
  loginWithTwitter()  {
    this.loading = true;
    this.authService.doTwitterLogin().then(res => {
	  console.log('Twitter login response', res);
      const uid = res.user.uid;
      const additionalUserInfo = res.additionalUserInfo.profile;
      this.checkUserAfterLogin('twitter', uid, additionalUserInfo);

    }, err => {
      console.log('err', err);
	  this.loading = false;
	  this.errorMessage = 'Something went wrong, please try again or sign up below';
      if (err.code === 'auth/account-exists-with-different-credential') {
        //this.linkAccount(err.credential, err.email);
      } 
	  this.manualLogout();
    });
  }
  loginWithFacebook() {
    this.authService.doFacebookLogin().then(res => {
	  console.log('Facebook login response', res);
      const uid = res.user.uid;
      const additionalUserInfo = res.additionalUserInfo.profile;
      this.checkUserAfterLogin('facebook', uid, additionalUserInfo);

    }, err => {
      if (err.code === 'auth/account-exists-with-different-credential') {
        //this.linkAccount(err.credential, err.email);
      }
	  console.log('err', err);
      this.errorMessage = 'Something went wrong, please try again or sign up below';
	  this.manualLogout();
    });
  }
  getProvider(providerId) {
    switch (providerId) {
      case firebase.auth.GoogleAuthProvider.PROVIDER_ID:
        return new firebase.auth.GoogleAuthProvider();
      case firebase.auth.FacebookAuthProvider.PROVIDER_ID:
        return new firebase.auth.FacebookAuthProvider();
      case firebase.auth.GithubAuthProvider.PROVIDER_ID:
        return new firebase.auth.GithubAuthProvider();
        case firebase.auth.TwitterAuthProvider.PROVIDER_ID:
        return new firebase.auth.TwitterAuthProvider();
      default:
        throw new Error(`No provider implemented for ${providerId}`);
    }
  }
  linkAccount(credentials, email) {
    this.credentials = credentials;
    const supportedPopupSignInMethods = [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.GithubAuthProvider.PROVIDER_ID,
      firebase.auth.TwitterAuthProvider.PROVIDER_ID
    ];
    firebase.auth().fetchSignInMethodsForEmail(email).then(providers => {
      const firstPopupProviderMethod = providers.find(p => supportedPopupSignInMethods.includes(p));
      // Test: Could this happen with email link then trying social provider?
      if (!firstPopupProviderMethod) {
        throw new Error(`Your account is linked to a provider that isn't supported.`);
      }

      const linkedProvider = this.getProvider(firstPopupProviderMethod);
      const el: HTMLElement = this.linkMultipleAccount.nativeElement;

      linkedProvider.setCustomParameters({ login_hint: email });
      this.linkedProvider = linkedProvider;
      setTimeout(() => {
        el.click();
      }, 1000);
    });
  }
  signInWithPopup(linkedProvider, credentials) {
    firebase.auth().signInWithPopup(linkedProvider).then(result => {
      result.user.linkWithCredential(credentials).then(res => {
        // setTimeout(() => {
		  console.log('res linkWithCredential', res);
          //this.checkUserStatus();
        //  }, 2000);
      });
    }, error => {
      if(error.code === 'auth/popup-blocked') {
        this.popupErrorMessage = 'Please click continue button to proceed further';
      } else {
        this.errorMessage = 'Something went wrong, please try again';
      }
    });
  }
  checkUserAfterLogin(provider, uid, additionalUserInfo) {
    console.log('checkuser');
	console.log(provider);
	console.log(uid);
	console.log(additionalUserInfo);
	this.userService.getCurrentUser().then(user => {
        if (user.roles.admin === true) {
          this.spinner.hide();
          this.global.loggedin = true;
          this.router.navigate(['/dashboard']);
        } else {
          this.spinner.hide();
          this.authService.doLogout().then(logout => {
            this.errorMessage = 'You don\'t have access to admin panel';
            this.notifier.notify( 'error', this.errorMessage);
            this.global.loggedin = false;
          }).catch(error => {
            console.log(error);
          });
        }
    });
  }
  
  manualLogout() {
    this.authService.doLogout().then(logout => {
		this.global.loggedin = false;
	}).catch(error => {
		console.log(error);
	});
  }
}
