import { Component, OnInit, ViewChild } from '@angular/core';
import firebase from 'firebase/app';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { MatRadioModule } from '@angular/material/radio';

import { ConfigService } from '../services/config.service';
import { UsersService } from '../services/users.service';
import { ServerConfigService } from '../services/serverconfig.service';
import { FaucetConfigService } from '../services/faucetconfig.service';
import { EditConfigDialog } from '../dialog-edit-config/dialog-edit-config';
import { from } from 'rxjs';

@Component({
  selector: 'app-systemconfiguration',
  templateUrl: './systemconfiguration.component.html',
  styleUrls: ['./systemconfiguration.component.css']
})

export class SystemconfigurationComponent implements OnInit {
  user: any;
  accessToken: string;

  displayedColumns = ['name', 'value', 'edit'];
  serverConfigDatabase = new ServerConfigDatabase();
  dataConfigServer: ServerConfigDataSource | null;
  faucetConfigDatabase = new FaucetConfigDatabase();
  dataConfigFaucet: FaucetConfigDataSource | null;
  clientConfigDatabase = new ClientConfigDatabase();
  dataConfigClient: ClientConfigDataSource | null;
  
  @ViewChild(MatSort) sort: MatSort;
   
  constructor(
    public configService: ConfigService,
    public userService: UsersService,
    private serverConfig: ServerConfigService,
    private faucetConfig: FaucetConfigService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.userService.getCurrentUser().then(res => {
      if (res) {
        this.user = res;
        firebase.auth().currentUser.getIdToken().then(authToken => {
          this.accessToken = authToken;
          // this.getServerConfig();
        });
        
        this.dataConfigServer = new ServerConfigDataSource(this.serverConfigDatabase);
        this.serverConfig.getServerConfig().subscribe((serverConfigData: any) => {
          const configs: ConfigData[] = [];
          for (var key in serverConfigData) {
            if (key != 'gmail') {
              configs.push({'name': key, 'value': serverConfigData[key], 'table': 'Server'});
            }
          }
          this.serverConfigDatabase.loadData(configs);
        });
        
        this.dataConfigClient = new ClientConfigDataSource(this.clientConfigDatabase);
        this.serverConfig.getClientConfig().subscribe((clientConfigData: any) => {
          
          const configs: ConfigData[] = [];
          for (var key in clientConfigData) {
            configs.push({'name': key, 'value': clientConfigData[key], 'table': 'Client'});
          }
          this.clientConfigDatabase.loadData(configs);
        });
        this.dataConfigFaucet = new FaucetConfigDataSource(this.faucetConfigDatabase);
        this.faucetConfig.getFaucetConfig().subscribe((faucetConfigData: any) => {
          // console.log('faucetConfigData', faucetConfigData);
          const configs: ConfigData[] = [];
          for (var key in faucetConfigData) {
            if (key != 'gmail') {
              configs.push({'name': key, 'value': faucetConfigData[key], 'table': 'Faucet'});
            }
          }
          this.faucetConfigDatabase.loadData(configs);
        });
      }
    });
  }
  
  ngAfterViewInit() {
    //this.serverConfigDatabase.sort = this.sort;
  }

  openDialog(configData) {
    const dialogRef = this.dialog.open(EditConfigDialog, {
      width: '500px',
      data: {systemdata: configData }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
    
  }
}

export interface ConfigData {
  name: string;
  value: string;
  table: string;
}


export class ServerConfigDatabase {
  dataChange: BehaviorSubject<ConfigData[]> = new BehaviorSubject<ConfigData[]>([]);
  get data(): ConfigData[] { return this.dataChange.value; }

  loadData(data) {
    this.dataChange.next(data);
  }
}

export class ServerConfigDataSource extends DataSource<any> {
 
  private lessonsSubject = new BehaviorSubject<ConfigData[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
    
    
  constructor(private _serverConfigDatabase: ServerConfigDatabase) {
    super()
  }
 
  //collectionViewer: CollectionViewer
  connect(): Observable<ConfigData[]> {
    return this._serverConfigDatabase.dataChange;
  }
  //collectionViewer: CollectionViewer
  disconnect(): void {
 
  }
}

export class ClientConfigDatabase {
  dataChange: BehaviorSubject<ConfigData[]> = new BehaviorSubject<ConfigData[]>([]);
  get data(): ConfigData[] { return this.dataChange.value; }

  loadData(data) {
    this.dataChange.next(data);
  }
}

export class ClientConfigDataSource extends DataSource<any> {
 
  private lessonsSubject = new BehaviorSubject<ConfigData[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
    
    
  constructor(private _clientConfigDatabase: ClientConfigDatabase) {
    super()
  }
 
  //collectionViewer: CollectionViewer
  connect(): Observable<ConfigData[]> {
    return this._clientConfigDatabase.dataChange;
  }
  //collectionViewer: CollectionViewer
  disconnect(): void {
 
  }
}

export class FaucetConfigDatabase {
  dataChange: BehaviorSubject<ConfigData[]> = new BehaviorSubject<ConfigData[]>([]);
  get data(): ConfigData[] { return this.dataChange.value; }

  loadData(data) {
    this.dataChange.next(data);
  }
}

export class FaucetConfigDataSource extends DataSource<any> {
 
  private lessonsSubject = new BehaviorSubject<ConfigData[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
    
    
  constructor(private _faucetConfigDatabase: FaucetConfigDatabase) {
    super()
  }
 
  //collectionViewer: CollectionViewer
  connect(): Observable<ConfigData[]> {
    return this._faucetConfigDatabase.dataChange;
  }
  //collectionViewer: CollectionViewer
  disconnect(): void {
 
  }
}

